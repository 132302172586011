import  styled, { createGlobalStyle } from 'styled-components';

const seuClubeColors = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
const main = seuClubeColors && seuClubeColors.colorPrimary;
const secondary = seuClubeColors && seuClubeColors.colorSecondary;

export const GlobalStyle = createGlobalStyle`
  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-modal-content {
    width: 100%;
    max-width: 600px;

    background-color: #1d2226;
    border-radius: 0.5rem;
    padding: 1rem;
    position: relative;
  }

  .react-modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    color: ${main};

    background: transparent;
    border: 0;
    cursor: pointer;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const Container = styled.div`
  font-size: 1rem;
  color: ${secondary};
  margin: 0 auto;
  
  p {
    text-align: left;
    margin-bottom: 3rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    display: block;
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
  }
  
  input[type="file"] {
    border: 0;
    font-size: 0.9rem;
    border-radius: 0.7rem;
    width: 19rem;
    cursor: pointer;
    color: #${secondary};
    background: ${main};
    margin-bottom: 1rem;
  }

  button[type=submit] {
    border: 0;
    border-radius: 0.8rem;
    height: 2rem;
    width: 8rem;
    cursor: pointer;
    font-size: 1.1rem;
    color: ${secondary};
    background: ${main};
  }
`;



