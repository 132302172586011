import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { firebaseStorage } from 'js/library/utils/firebaseUtils';

export function downloadImage(pastaStorage, img, useStoragePath = false) {
  return new Promise((resolve, reject) => {
    let path;
    if (useStoragePath) {
      path = img;
    } else {
      path = pastaStorage + '/' + img;
    }

    const pathReference = ref(firebaseStorage, path);

    getDownloadURL(pathReference)
      .then(function (url) {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';

        xhr.open('GET', url);
        xhr.send();

        resolve(url);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function uploadImage(pastaStorage, newImg, newImageName) {
  const newStorageRef = ref(firebaseStorage, pastaStorage + '/' + newImageName);

  return new Promise((resolve, reject) => {
    uploadBytes(newStorageRef, newImg)
      .then(function (snapshot) {
        resolve(snapshot);
      })
      .catch(function (error) {
        // Handle any errors
        reject(error);
      });
  });
}
