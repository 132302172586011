import React, { useEffect } from 'react';
import { Buttons, DataContainer, Title } from '../styles';
import * as S from './styles';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getLastUserInfo, trackEventMatomo } from 'js/library/utils/helpers';
import SubscriptionModal from 'js/containers/Login/SubscriptionModal';

export default function PurchaseDetails({ productData, handleLoggedCheckout }) {
  const { t } = useTranslation();
  const [openState, setOpenState] = React.useState(false);

  const userInfo = getLastUserInfo();

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('cupom');
    localStorage.removeItem('discount_total');
    localStorage.removeItem('total');
  }, []);

  const handlePayment = () => {
    handleLoggedCheckout('personalData');
    trackEventMatomo('Detalhes do produto', 'click', 'button', 'Prosseguir para pagamento');
  };

  const handleSubscription = () => {
    const queryString = document.location.search;

    if (userInfo.triiboId.includes('@anonymous')) {
      setOpenState(true);
    } else {
      navigate(`/assinatura${queryString}`);
    }

    trackEventMatomo('Detalhes do produto', 'click', 'button', 'Utilizar código de ativação');
  };

  return (
    <DataContainer>
      <Title>{t('checkout.purchase_details')}</Title>

      <S.ProductImage src={productData.productImage.src} alt={productData.productImage.alt} />

      <S.ProductTitle>{productData.name}</S.ProductTitle>

      <span>
        {Number(productData.price).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </span>

      <S.ProductDescription dangerouslySetInnerHTML={{ __html: productData.description }} />

      <Buttons>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          onClick={handlePayment}
        >
          {t('checkout.go_to_payment')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubscription}
        >
          {t('checkout.activate_product')}
        </Button>
      </Buttons>

      <SubscriptionModal openState={openState} setOpenState={setOpenState} />
    </DataContainer>
  );
}
