import styled from 'styled-components';
import ReactInputMask from 'react-input-mask';

export const Container = styled.div`
  padding: 2rem;

  h1 {
    text-align: center;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Button = styled.button`
  height: 42px;
  width: 100%;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: ${({ bgColor }) => bgColor};
  box-shadow: 0px 3px 6px #00000029;
  color: #fff;
  font-weight: 600;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const BoxCellPhone = styled.div`
  width: 100%;
  border: 1px solid #afafaf;
  border-radius: 10px;
  height: 42px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-left: 0.5rem;

  select {
    font-size: 14px;
    height: 100%;
    border-radius: 10px;
    outline: none;
  }

  input {
    flex: 1;
    height: 100%;
    border-radius: 10px;
    outline: none;
  }
`;
export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Input = styled(ReactInputMask)`
  height: 42px;
  width: 100%;
  border-radius: 10px;
  border: none;
  padding: 0.7rem;
  outline: none;
  border: 1px solid #afafaf;

  ${({ cellphone }) =>
    cellphone &&
    `
    font-family: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif";
    align-content: center;
    height: 100%;
    border: none;
    outline: none;
    font-size: 17px;
    color: #383838d6;
    border-radius: 0px;
    flex-direction: row;
    justify-content: flex-end;
  `}
`;

export const Adornment = styled.div`
  color: #383838;
`;

export const ContainerAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  strong {
    margin-bottom: 0.3rem;
    font-size: 1.3rem;
  }
`;

export const ContentInfoAddress = styled.span`
  display: flex;
  gap: 1rem;
`;
