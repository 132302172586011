import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';

export function checkAuth(token) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${cfac22('API_HOST_V2')}/auth/marketplace`,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
            'x-api-key': cfac22('MARKETPLACE_K'),
          },
        }
      )
      .then((result) => {
        // console.log("RESULT_ADD_FAVORITES: ", result);
        resolve(result.data);
      })
      .catch((error) => {
        // console.log('ERROR_CHECK_AUTH: ', error);
        return reject(error);
      });
  });
}
