import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';

export function getStorePlacesItem_v2(storeId, establishmentId, uId) {
  return new Promise(function (resolve, reject) {
    getChannelToken_v1(uId)
      .then((result) => {
        //console.log('RESULTADO GET CHANNEL TOKEN ===>', result);

        axios
          .post(cfac22('API_HOST_V1') + 'getStorePlacesItem_v2', {
            triiboHeader: {
              apiToken: cfac22('apiToken'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: uId,
              channelTokenId: result,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            storeId: storeId,
            establishmentId: establishmentId,
          })
          .then((result) => {
            //console.log("RESULTADO STORE PLACES ITEM ===> ", result);
            resolve(result.data.success);
          })
          .catch((error) => {
            //console.log('ERROR STORE PLACES ITEM ===>', error);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR CHANNEL TOKEN ===>', error);
        reject(error);
      });
  });
}
