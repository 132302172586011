import React from 'react';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function ActivateSubscription() {
  const { t } = useTranslation();

  return (
    <div className="paper-container">
      <S.Container>
        <CheckCircleOutlineIcon
          sx={{
            fontSize: 80,
            color: '#2ce068',
          }}
        />

        <h2>{t('checkout.has_subscription')}</h2>
      </S.Container>
    </div>
  );
}
