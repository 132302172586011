import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from 'js/library/utils/cfac22/cfac22';

export default function createLead({
  uId,
  firstName,
  lastName,
  cpf,
  fone,
  email,
  productType,
  productId,
  quantity,
  address_1,
  address_2,
  city,
  state,
  postcode,
  country,
  neighborhood,
  number,
  subscriptionInfo,
}) {
  return new Promise((resolve, reject) => {
    let data = {
      userData: {
        firstName,
        lastName,
        // cpf,
        fone,
        email,
      },
      lead: {
        productType, //Tipo de produto 'subscription', "digital" ou "physical"
        lineItems: [
          {
            productId,
            quantity,
          },
        ],
      },
    };

    if (productType === 'physical') {
      data.lead.shipping = {
        address_1,
        address_2,
        city,
        state,
        postcode,
        country,
        neighborhood,
        number,
      };

      data.lead.billing = {
        address_1,
        address_2,
        city,
        state,
        postcode,
        country,
      };
    }

    if (productType === 'subscription') {
      data.lead.subscriptionInfo = {
        ...subscriptionInfo,
      };
    }

    authToken(uId)
      .then((token) => {
        axios
          .post(
            // eslint-disable-next-line no-undef
            `${cfac22('API_HOST_V2')}/checkout/lead`,
            {
              ...data,
            },
            {
              headers: { authorization: `Bearer ${token}` },
            }
          )
          .then(async (result) => {
            // console.log("RESULT_CreateLead: ", result);
            resolve(result.data);
          })
          .catch((error) => {
            //console.log("ERROR_CreateLead: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        //console.log("ERROR_TOKEN:", error);
        reject(error);
      });
  });
}
