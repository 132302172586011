import React from 'react';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import './styleEstablishmentLinks.css';

function LoadingInfoEstablishment() {
  const { t } = useTranslation();

  return (
    <div className="estab-loading-info">
      <CircularProgress size="2rem" style={{ color: '#6d3196' }} />
      <h1>{t('general.wait_we_are_loading_info')}</h1>
    </div>
  );
}

export default LoadingInfoEstablishment;

