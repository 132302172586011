import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatDate } from 'js/library/utils/helpers';

export default function StatementTable({ pointsBalance }) {
  const pointsArray = Object.keys(pointsBalance)
    .map((row) => {
      if (row !== 'consolidateBalance') {
        return { id: row, ...pointsBalance[row] };
      }
      return null;
    })
    .filter(Boolean);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              Data
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              Descrição
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              Pts
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pointsArray
            .sort((a, b) => b.dataEvento - a.dataEvento)
            .map((item) => (
              <TableRow
                key={item.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&>td': { fontSize: 14 },
                }}
              >
                <TableCell align="center">{formatDate(item.dataEvento)}</TableCell>
                <TableCell align="center" sx={{ fontSize: '12px !important' }}>
                  {item.descricao}
                </TableCell>
                <TableCell align="center">{item.valor}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
