import React from 'react';
import { WhatsApp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

function BusinessContact({ contatoComercial }) {
  const { t } = useTranslation();

  return (
    <div>
      {contatoComercial.map((item, index) => {
        return (
          item.type === 'whatsapp' && (
            <div key={item.type} className="text-social-media-estab-links">
              <span>{t('general.contact')}</span>
            </div>
          )
        );
      })}

      <div className="content-social-media-estab-links">
        {contatoComercial &&
          contatoComercial.map((item, index) => {
            const cellphone = item.value.replace(/\D/g, '');

            return (
              item.type === 'whatsapp' && (
                <a
                  key={index}
                  href={`https://api.whatsapp.com/send?phone=${cellphone}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <WhatsApp />
                </a>
              )
            );
          })}
      </div>
    </div>
  );
}

export default BusinessContact;
