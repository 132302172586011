import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogTitle, Slide } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getClubIdEnvironment, trackEventMatomo } from 'js/library/utils/helpers';
import { getSeuClube } from 'js/library/utils/API/seuClube';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SubscriptionModal(props) {
  const { openState, setOpenState } = props;

  const navigate = useNavigate();

  const { t } = useTranslation();
  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
  const clubeId = getClubIdEnvironment();
  const [colorPrimary, setColorPrimary] = React.useState('');
  const [colorSecondary, setColorSecondary] = React.useState('');

  const handleClose = () => {
    trackEventMatomo(document.title, 'tocar', 'Modal assinatura', 'Voltar');
    setOpenState(false);
  };

  const loginUser = (e) => {
    e.preventDefault();
    trackEventMatomo(document.title, 'tocar', 'Modal anonimo', 'Assinar agora');
    sessionStorage.setItem('loginOption', '3');

    let urlRedirect = new URL(infoSeuClube.subscriptionLink);
    urlRedirect = urlRedirect.pathname + urlRedirect.search;
    sessionStorage.setItem('loginRedirect', urlRedirect);
    navigate('/validarAcesso/?url=' + urlRedirect);
    setOpenState(false);
  };

  useEffect(() => {
    try {
      if (infoSeuClube === null || infoSeuClube === undefined) {
        (function seuClube() {
          getSeuClube(clubeId).then((dataClube) => {
            if (dataClube !== undefined) {
              setColorPrimary(dataClube.colorPrimary);
              setColorSecondary(dataClube.colorSecondary);
            }
          });
        })();
      }
    } catch (error) {
      console.log('API ERROR', error);
    }
    // eslint-disable-next-line
  }, [infoSeuClube]);

  return (
    <Dialog
      open={openState}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleClose();
        trackEventMatomo(document.title, 'tocar', 'Modal assinatura', 'Fechar modal');
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{t('subscription.title')}</DialogTitle>

      <DialogActions style={{ alignSelf: 'center' }}>
        <Button
          variant="contained"
          onClick={handleClose}
          style={{
            background: infoSeuClube ? infoSeuClube.colorSecondary : colorSecondary,
            fontWeight: 900,
            color: infoSeuClube ? infoSeuClube.colorPrimary : colorPrimary,
          }}
        >
          {t('general.back')}
        </Button>

        <Link to={infoSeuClube.subscriptionLink} style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            style={{
              background: infoSeuClube ? infoSeuClube.colorPrimary : colorPrimary,
              fontWeight: 900,
              color: infoSeuClube ? infoSeuClube.colorSecondary : colorSecondary,
            }}
            onClick={loginUser}
          >
            {t('subscription.action')}
          </Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
}

