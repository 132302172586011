import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.div`
  padding: 2rem;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 300px;
  width: 100%;
  text-align: center;
`;

export const Button = styled.button`
  height: 42px;
  width: 100%;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: ${({ bgColor }) => bgColor};
  color: #fff;
  font-weight: 600;

  :disabled {
    cursor: default;
    background-color: #ccc;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BoxCellPhone = styled.div`
  border: 1px solid #afafaf;
  border-radius: 10px;
  height: 42px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-left: 0.5rem;

  select {
    font-size: 14px;
    height: 100%;
    border-radius: 10px;
    outline: none;
  }

  input {
    flex: 1;
    height: 100%;
    border-radius: 10px;
    outline: none;
  }
`;

export const Input = styled(InputMask)`
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  color: #383838d6;
  border-radius: 10px;
`;

export const Adornment = styled.div`
  color: #383838;
`;

export const Link = styled.span`
  color: blue;
  cursor: pointer;
  text-decoration: underline;
`;
