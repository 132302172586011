import { IconButton, MenuItem } from '@mui/material';
import { AccountBalanceWallet } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MenuItemDrawer = styled(MenuItem)`
  color: #000;
  cursor: ${(props) => props.cursor};
  font-size: ${(props) => (props.fs ? props.fs : '1rem')};
  text-decoration: none;
  font-weight: ${(props) => props.fontweight};

  a {
    color: #000;
    text-decoration: none;
  }
`;

export const MenuWeb = styled.div`
  color: #fff;
  display: flex;
`;

export const ContainerTopBar = styled.div`
  background: ${(props) => props.$bg};
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding: 0 1rem;

  @media (max-width: 900px) {
    justify-content: space-between;
    align-items: center;
  }
`;

export const IconBtn = styled(IconButton)`
  position: relative;
  display: inline-block;
  border-radius: 0 !important;
`;

export const HoverBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: ${(props) => props.$bg};
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;

  ${IconBtn}:hover & {
    transform: scaleX(1);
    border-radius: 0 !important;
  }
`;

export const UserContainer = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 2px 4px 10px 0px #0000003d;
  height: ${(props) => (props.$containerheight ? '8rem' : '15rem')};
  width: 18rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 2rem 0 0.5rem 0%;
  }

  h2 {
    margin: 1rem 0 0 0;
    line-height: 1;
  }

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 1228px) {
    background-color: white;
    border-radius: 18px;
    height: ${(props) => (props.height ? '8rem' : '15rem')};
    width: 16rem;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 1090px) {
    width: 15rem;

    img {
      margin-top: 2rem;
      height: ${(props) => (props.height ? '6rem' : '13rem')};
      width: 6rem;
      border-radius: 50%;
    }

    h2 {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 1020px) {
    width: 14rem;
    height: ${(props) => (props.height ? '8rem' : '13rem')};

    h2 {
      margin: 0;
    }
  }
`;

export const UserContentLoggedout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0 2rem 0;
`;

export const ButtonLogin = styled.button`
  background: ${(props) => props.$bg && props.$bg};
  color: ${(props) => props.color && props.color};
  border: 0;
  border-radius: 10px;
  box-shadow: 2px 4px 10px 0px #0000003d;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 2.5rem;
`;

export const UserContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0 2.5rem 0;
  text-align: center;

  span {
    font-size: 2rem;
    font-weight: 800;
  }

  @media screen and (max-width: 1020px) {
    margin: 3rem 0 1rem 0;
  }
`;

export const PointAreaMobile = styled.div`
  @media (min-width: 900px) {
    display: none;
  }
`;

export const PointArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;

  div {
    align-items: center;
    text-align: center;
  }

  span {
    font-size: 1rem;
    font-weight: 700;
  }

  @media (max-width: 900px) {
    gap: 10px;

    flex-direction: row;

    color: #fff;
  }

  @media (max-width: 600px) {
    font-size: 1rem;

    span {
      font-size: 0.8rem;
    }
  }
`;

export const Line = styled.hr`
  max-width: 100%;
  width: 18rem;

  @media screen and (max-width: 1228px) {
    width: 16rem;
  }

  @media screen and (max-width: 1090px) {
    width: 15rem;
  }

  @media screen and (max-width: 1020px) {
    width: 14rem;
  }
`;

export const LeftBanner = styled.div`
  text-decoration: none;
  img {
    border-radius: 20px;
    box-shadow: 2px 4px 10px 0px #0000003d;
    margin-top: 2rem;
    max-height: 20rem;
    width: 18rem;

    @media screen and (max-width: 1228px) {
      border-radius: 14px;
      max-height: 18rem;
      width: 16rem;
    }

    @media screen and (max-width: 1090px) {
      width: 15rem;
    }

    @media screen and (max-width: 1020px) {
      margin-top: 1rem;
      max-height: 17rem;
      width: 14rem;
    }
  }
`;

export const LeftColumn = styled.div`
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const CenterColumn = styled.div`
  margin: 0 auto;
  max-width: 100%;
  width: 1800px;
`;

export const RightColumn = styled.div`
  img {
    border-radius: 18px;
    box-shadow: 2px 4px 10px 0px #0000003d;
    max-height: 25rem;
    width: 18rem;

    @media screen and (max-width: 1228px) {
      max-height: 23rem;
      width: 16rem;
    }

    @media screen and (max-width: 1090px) {
      max-height: 22rem;
      width: 15rem;
    }

    @media screen and (max-width: 1020px) {
      max-height: 21rem;
      width: 14rem;
    }

    @media screen and (max-width: 900px) {
      display: none;
    }
  }
`;

export const Wallet = styled(AccountBalanceWallet)`
  margin: 0.5rem 1rem;
  padding: ${(props) => props.padding};
`;

export const MenuMobile = styled.ul`
  display: none;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0px 16px;
  width: 100%;
  background-color: ${({ $bg }) => ($bg ? $bg : '#fff')};
  box-shadow: 2px 4px 10px 0px #0000003d;
  list-style: none;
  text-align: center;
  z-index: 2;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 5px;

    a {
      display: block;
    }
  }

  @media screen and (max-width: 600px) {
    display: flex;
  }
`;

export const DisplayFlex = styled.div`
  display: flex;
  align-items: ${(props) => props.align};
  align-content: ${(props) => props.$aligncontent};
  justify-content: ${(props) => props.content};
  background-color: ${(props) => props.$bg};
`;

export const MenuItemList = styled(Link)`
  color: #000;
  text-decoration: none;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  @media screen and (max-width: 959px) {
    padding: 0.5rem 1rem;
  }
`;

export const LinkHtml = styled.a`
  color: #000;
  text-decoration: none;
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  @media screen and (max-width: 959px) {
    padding: 0.5rem 1rem;
  }
`;

export const PageInactiveClube = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.bgcolor};
  color: ${(props) => props.colorfont};
  font-size: 2rem;
  height: 100vh;
`;

