import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { trackEventMatomoVisit } from 'js/library/utils/helpers';

// STYLES
import { DataContainer, Title } from '../styles';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';

// ICONS
import { ContentCopy } from '@mui/icons-material';

// TRANSLATE
import { useTranslation } from 'react-i18next';

// COMPONENTS
import ModalPix from './modalPix';

// APIS
import generatePixQrCode from 'js/library/utils/API/payment/generatePixQrCode';
import { useNavigate } from 'react-router-dom';

export default function PixQrCode({ leadInfos, uId }) {
  const { t } = useTranslation();
  const [qrCode, setQrCode] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const total = localStorage.getItem('total');

  // timer de vencimento do qrcode(pix)
  const threeHoursInSeconds = 3 * 60 * 60; // 3 horas em segundos
  const [timeLeft, setTimeLeft] = useState(threeHoursInSeconds);

  useEffect(() => {
    if (timeLeft <= 0) return; // para o timer se o tempo acabar

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  // Função para formatar o tempo em horas, minutos e segundos
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
      secs
    ).padStart(2, '0')}`;
  };

  useEffect(() => {
    trackEventMatomoVisit('Pagamento - Pix');
    document.title = 'Pagamento - Pix';
  }, []);

  useEffect(() => {
    if (qrCode === null) {
      generatePixQrCode(uId, leadInfos.id)
        .then((resultPix) => {
          setQrCode(resultPix);
        })
        .catch(() => {
          setQrCode(false);
        });
    }
  }, [leadInfos, uId, qrCode]);

  const copyCode = () => {
    navigator.clipboard
      .writeText(qrCode.qrCodeString)
      .then(() => {
        toast.success('Código Pix copiado para área de transferência.');
      })
      .catch((err) => {
        console.error('Ocorreu um erro ao tentar copiar o código pix: ', err);
        copyWithCommand();
      });
  };

  const copyWithCommand = () => {
    if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      var textarea = document.createElement('textarea');
      textarea.textContent = qrCode.qrCodeString;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.select();

      try {
        toast.success(t('checkout.code_copy'));

        return document.execCommand('copy');
      } catch (err) {
        console.error('Could not copy text with command: ', err);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  const statusPayment = qrCode?.statusPayment;

  const handlePaymentConfirmed = () => {
    if (qrCode !== null) {
      if (statusPayment === 'Authorized' || statusPayment === 'PaymentConfirmed') {
        navigate('/pagamento-confirmado');
      } else if (statusPayment === 'Pending' || statusPayment === 'NotFinished') {
        setOpenModal(true);
      } else {
        navigate('/pagamento-cancelado');
      }
    }
  };

  const renderPix = () => {
    switch (qrCode) {
      case null:
        return <CircularProgress />;
      case false:
        return <Typography>{t('checkout.pix_error')}</Typography>;
      default:
        return (
          <>
            <Box width="100%" display="flex" gap="10px">
              <Box
                sx={{
                  backgroundColor: '#f5f5f5',
                  borderRadius: '8px',
                  textAlign: 'center',
                  padding: '20px 0px',
                }}
                width="50%"
              >
                <Typography variant="h6">
                  {t('checkout.value')}:
                  <br />
                  <span style={{ fontWeight: 700 }}>
                    {Number(total ?? leadInfos.total).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: '#f5f5f5',
                  borderRadius: '8px',
                  textAlign: 'center',
                  padding: '20px 0px',
                }}
                width="50%"
              >
                <Typography variant="h6">
                  {t('checkout.expiration')}:
                  <br />
                  <span style={{ fontWeight: 700 }}>{formatTime(timeLeft)}</span>
                </Typography>
              </Box>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <img
                style={{ width: '70%' }}
                src={'data:image/png;base64, ' + qrCode.qrCodeImage}
                className="qrCode-image"
                alt="qr-code"
              />
            </Box>

            <div className="pix-paper" style={{ alignSelf: 'center' }}>
              <div>
                <Typography variant="subtitle1" style={{ paddingBottom: 20 }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('checkout.pix_instructions'),
                    }}
                  />
                </Typography>
              </div>

              <OutlinedInput
                style={{ width: '100%' }}
                type="text"
                fullWidth
                value={qrCode.qrCodeString}
                disabled
                id="pixCode"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="Copiar código" onClick={() => copyCode()}>
                      <ContentCopy />
                    </IconButton>
                  </InputAdornment>
                }
              />

              <div
                style={{
                  marginTop: 15,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handlePaymentConfirmed}
                >
                  {t('checkout.confirm_payment')}
                </Button>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <DataContainer>
      <Title>{t('checkout.pix_page_title')}</Title>

      <ModalPix open={openModal} setOpen={setOpenModal} />

      {timeLeft <= 0 ? (
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          Pix expirado!
        </Typography>
      ) : (
        <div style={{ placeSelf: 'center', paddingTop: 10 }}>{renderPix()}</div>
      )}
    </DataContainer>
  );
}
