import axios from 'axios';

import cfac22 from 'js/library/utils/cfac22/cfac22';
import { getChannelTokenUidNull } from 'js/library/utils/API/getChannelTokenUidNull_v1';

//buscando usuario p/ login
//busca simples
export function getUserInfoBasic_v1(queryPartnerAPI, userInfo) {
  return new Promise((resolve, reject) => {
    getChannelTokenUidNull()
      .then((result) => {
        //console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', result);

        //TODO capturar sessionId e transactionId
        axios
          .post(cfac22('API_HOST_V1') + 'getUserInfo_v1', {
            triiboHeader: {
              apiToken: cfac22('apiToken'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: null,
              channelTokenId: result,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then((result) => {
            //console.log('RESULTADO DA API GET INFO ===>', result);

            return resolve(result.data.success.userInfo);
          })
          .catch((error) => {
            console.log('ERROR GET USER INFO ===>', error);

            return reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
        return reject(error);
      });
  });
}
