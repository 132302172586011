import React, { useContext } from 'react';
import Modal from 'react-modal';

import { useTranslation } from 'react-i18next';
import { SignUpContext } from 'js/context/SignUpContext';

import './modalCouponStyles.css';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import { colorText, darkenColor } from 'js/library/utils/helpers';

export function ModalCoupon({ storeInfo, isOpen, onRequestClose, storeUrl }) {
  const { t } = useTranslation();
  const { infosSeuClube } = useContext(SignUpContext);

  Modal.setAppElement('#seu-clube');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'bolder' }}>
            {storeInfo.establishmentName}
          </Typography>
          <IconButton onClick={() => onRequestClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{ fontWeight: 'bolder', whiteSpace: 'pre-line', color: infosSeuClube.colorPrimary }}
          >
            {storeInfo.title}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography
            variant="body1"
            style={{
              userSelect: 'none',
              fontWeight: 'bold',
              display: 'flex',
              border: '2px dashed ',
              borderColor: infosSeuClube.colorPrimary,
              color: '#000',
              padding: '15px 20px',
              borderRadius: '10px',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {storeInfo.checkoutCode}
            <LocalOfferRoundedIcon sx={{ color: infosSeuClube.colorPrimary }} />
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="subtitle2" sx={{ marginBottom: '10px' }}>
            {t('modalCoupon.instructions_use')}
          </Typography>
          <Button
            variant="contained"
            sx={{
              background: infosSeuClube.colorPrimary,
              color: colorText(infosSeuClube.colorPrimary),
              '&:hover': {
                background: darkenColor(infosSeuClube.colorPrimary, 30),
              },
            }}
            onClick={() => {
              navigator.clipboard.writeText(storeInfo.checkoutCode).then(() => {
                window.open(storeUrl, '_blank');
              });
            }}
          >
            {t('modalCoupon.visit_store')}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}

