import { styled } from '@mui/system';
import { Dialog } from '@mui/material';

export const CustomizedModal = styled(Dialog)({
    paper: {
        overflowY: 'inherit !important'
    },
    '& .MuiDialog-paper': {
        overflowY: 'inherit !important'
    },
});