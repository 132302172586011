import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';
import { useTranslation } from 'react-i18next';
import InformCellPhone from './InformCellphone';
import ValidateSMS from './ValidateSMS';
import { SignUpContext } from 'js/context/SignUpContext';
import { toast } from 'react-toastify';
import { CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { authCode_v1 } from 'js/library/utils/API/authCode_v1';
import { theme } from 'index';
import { trackEventMatomoVisit, getLastUserInfo } from 'js/library/utils/helpers';
import { checkUserStatus } from 'js/library/services/AuthenticationManager.js';

export default function ValidateCellphone() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentModel, setCurrentModel] = useState('loading');
  const {
    formFields,
    cellPhone,
    setFormData,
    sendCodeType,
    formData,
    setAuthSmsInfos,
    validateInfos,
  } = useContext(SignUpContext);

  const ConditionalRendering = () => {
    const renderModels = {
      cellphone: <InformCellPhone setCurrentModel={setCurrentModel} />,
      sms: <ValidateSMS />,
      loading: (
        <CircularProgress
          size={50}
          style={{ margin: '0 auto', color: theme.palette.primary.main }}
        />
      ),
    };

    return renderModels[currentModel];
  };

  useEffect(() => {
    const userInfo = getLastUserInfo();
    let isAnonymous = userInfo !== null;
    isAnonymous = isAnonymous ? userInfo.triiboId?.indexOf('@anonymous-triibo,com,br') >= 0 : false;

    checkUserStatus().then(async (response) => {
      if (response && !isAnonymous) {
        return navigate('/');
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!validateInfos || formFields.length < 1) {
      navigate('/');
      return;
    }
    const field = formFields.find((field) => field.indexKey === true);

    if (field.fieldType === 'cellPhone') {
      authCode_v1(null, cellPhone, sendCodeType, '/')
        .then((res) => {
          if (res.error) {
            return toast.error(t('errors.try_later'));
          }

          setAuthSmsInfos(res);
          if (!validateInfos.user) {
            setFormData({
              ...formData,
              contactList: formData['contactList']
                ? [
                    ...formData['contactList'],
                    {
                      type: 'cellPhone',
                      value: cellPhone,
                    },
                  ]
                : [
                    {
                      type: 'cellPhone',
                      value: cellPhone,
                    },
                  ],
              updateDate: new Date().getTime(),
            });
          }

          setCurrentModel('sms');
        })
        .catch((error) => {
          if (!validateInfos.user) {
            setFormData({
              ...formData,
              contactList: formData['contactList']
                ? [
                    ...formData['contactList'],
                    {
                      type: 'cellPhone',
                      value: cellPhone,
                    },
                  ]
                : [
                    {
                      type: 'cellPhone',
                      value: cellPhone,
                    },
                  ],
              updateDate: new Date().getTime(),
            });
          }
          setCurrentModel('sms');
          return toast.error(t('errors.try_later'));
        });
    } else {
      if (validateInfos.cellPhone) {
        authCode_v1(null, cellPhone, sendCodeType, '/')
          .then((res) => {
            if (res.error) {
              return toast.error(t('errors.try_later'));
            }

            setAuthSmsInfos(res);
            if (!validateInfos.user) {
              setFormData({
                ...formData,
                contactList: formData['contactList']
                  ? [
                      ...formData['contactList'],
                      {
                        type: 'cellPhone',
                        value: cellPhone,
                      },
                    ]
                  : [
                      {
                        type: 'cellPhone',
                        value: cellPhone,
                      },
                    ],
                updateDate: new Date().getTime(),
              });
            }

            setCurrentModel('sms');
          })
          .catch((error) => {
            if (!validateInfos.user) {
              setFormData({
                ...formData,
                contactList: formData['contactList']
                  ? [
                      ...formData['contactList'],
                      {
                        type: 'cellPhone',
                        value: cellPhone,
                      },
                    ]
                  : [
                      {
                        type: 'cellPhone',
                        value: cellPhone,
                      },
                    ],
                updateDate: new Date().getTime(),
              });
            }
            setCurrentModel('sms');
            return toast.error(t('errors.try_later'));
          });
      } else {
        setCurrentModel('cellphone');
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    trackEventMatomoVisit('validarCelular');
  }, []);

  return (
    <S.Container className="paper-container">
      <Typography variant="body2" style={{ fontSize: '17px' }}>
        {currentModel === 'cellphone'
          ? t('general.tell_your_phone_number')
          : currentModel === 'loading'
          ? t('general.you_will_receive_auth_code')
          : currentModel === 'sms' && sendCodeType.sms === true
          ? t('general.you_will_receive_auth_code_in_seconds')
          : t('general.you_will_receive_auth_code_in_seconds_WPP')}
      </Typography>

      {ConditionalRendering()}
    </S.Container>
  );
}
