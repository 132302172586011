import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export default function getPowerBI(configId, uid) {
  return new Promise((resolve, reject) => {
    authToken(uid)
      .then((token) => {
        axios
          .get(
            cfac22('API_HOST_V2') + '/powerBI/embedInfo?configId=' + configId,
            {
              headers: { authorization: 'Bearer ' + token },
            }
          )
          .then((result) => {
            // console.log("RESULTADO API GETPOWERBI ===>: ", result);
            resolve(result.data);
          })
          .catch((error) => {
            //console.log("ERRO API POWERBI ===>: ", error);
            return reject(error.response.data);
          });
      })
      .catch((error) => {
        // console.log("ERROR_TOKEN ===>", error);
        reject(error.response.data);
      });
  });
}
