import { Box } from '@mui/material';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { getLastUserInfo, getUrlVariables } from 'js/library/utils/helpers';
import React from 'react';
import { useParams } from 'react-router-dom';
import { AllModules } from 'synoro-package/packages';

export default function LandingPage() {
  const urlVars = getUrlVariables();
  const props = useParams();
  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  const id = props.id;
  const userInfo = getLastUserInfo();

  return (
    <Box
      sx={{
        marginTop: '75px',
        minHeight: '86vh',
      }}
    >
      <AllModules
        id={urlVars.id}
        publicName={id || urlVars.publicName}
        partnerId={infoSeuClube.partnerId}
        userInfo={userInfo}
        ambient={cfac22('ambient')}
      />
    </Box>
  );
}
