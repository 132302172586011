import React from 'react';
import { Link } from 'react-router-dom';

import { Typography, Grid, Paper } from '@mui/material';

import { trackEventMatomoElementId } from 'js/library/utils/helpers';

import { downloadImage } from 'js/library/services/StorageManager.js';
import placeholder from 'styles/assets/placeholder/placeholder.gif';

export const Establishment = (data) => {
  const [downloadedImage, setDownloadedImage] = React.useState(null);

  const distance = data.data.distance;
  const id = data.data.id;
  data = data.data[data.data.type];
  data.id = id;

  const verifyDistance = () => {
    if (distance !== null && typeof distance === 'number') {
      if (distance < 1) {
        return distance.toFixed(2) * 1000 + 'm';
      } else {
        return distance?.toFixed(2) + 'km';
      }
    } else {
      return null;
    }
  };

  function downloadImageOnLoad() {
    if (data.fotoThumb !== undefined && downloadedImage === null) {
      downloadImage('estabelecimento', data.fotoThumb)
        .then((downloaded) => {
          setDownloadedImage(downloaded);
        })
        .catch((error) => {
          return error;
        });
    }
  }

  return (
    <Paper elevation={3} style={{ margin: '15px 2px', padding: '20px', borderRadius: '16px' }}>
      <Grid className="container-card-offer">
        <Grid item xs={12} md={5}>
          <Link
            onClick={() => trackEventMatomoElementId('ListaDePromocao', 'tocar', 'card', data.id)}
            to={{ pathname: '/estabelecimento/', search: '?id=' + data.id, state: data }}
            style={{ textDecoration: 'none' }}
          >
            <img
              width="100%"
              alt="thumbnail estabelecimento"
              onLoad={() => downloadImageOnLoad()}
              className="img-offer"
              src={downloadedImage === null ? placeholder : downloadedImage}
            />
          </Link>
        </Grid>

        <Grid item xs={12} md={7}>
          <Link
            onClick={() => trackEventMatomoElementId('ListaDePromocao', 'tocar', 'card', data.id)}
            to={{ pathname: '/estabelecimento/', search: '?id=' + data.id, state: data }}
            style={{ textDecoration: 'none' }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
                lineHeight: '1.0',
                marginTop: '10px',
                marginBottom: '10px',
                maxWidth: '80%',
                color: '#444',
              }}
            >
              {data.nome}
            </Typography>
            <Typography
              className="details-offer"
              variant="body2"
              paragraph
              style={{ fontSize: '0.9rem' }}
            >
              {data.descricao}
            </Typography>

            <Typography
              variant="caption"
              style={{
                maxWidth: '80%',
                color: '#000',
              }}
            >
              {data.endereco === 'Brasil' || distance === null ? null : verifyDistance()}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Establishment;
