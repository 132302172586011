import React, { useEffect, useState } from 'react';

import { Button, InputAdornment, TextField, CircularProgress, Typography } from '@mui/material';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import MasterCardIcon from 'styles/assets/icons/master-brand.svg';
import VisaIcon from 'styles/assets/icons/visa-brand.svg';
import EloIcon from 'styles/assets/icons/elo-brand.svg';
import PixIcon from '@mui/icons-material/Pix';
import SellIcon from '@mui/icons-material/Sell';

import * as S from './styles';
import { Buttons, DetailItem, DataContainer, Title } from '../styles';
import {
  creditCardMask,
  getLastUserInfo,
  trackEventMatomo,
  trackEventMatomoVisit,
} from 'js/library/utils/helpers';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ApplydiscountCoupon from 'js/library/utils/API/payment/apiApplydiscountCoupon';

export default function CardData({
  setData,
  data,
  setCurrentComponent,
  handleSubmit,
  loading,
  productData,
}) {
  const { t } = useTranslation();

  const [creditCardCVV, setCreditCardCVV] = useState('');
  const [isValidCardNumber, setIsValidCardNumber] = useState(true);
  const [validity, setValidity] = useState('');
  const [paymentType, setPaymentType] = useState('credit');

  const [cupom, setCupom] = useState(localStorage.getItem('cupom') ?? '');
  const [loadingCupom, setLoadingCupom] = useState(false);

  const cupomValue = localStorage.getItem('discount_total') || '';
  const totalValue = localStorage.getItem('total') || '';

  const userInfo = getLastUserInfo();

  useEffect(() => {
    trackEventMatomoVisit('Pagamento - Cartão');
    document.title = 'Pagamento - Cartão';

    if (cupom === '') {
      localStorage.removeItem('discount_total');
      localStorage.removeItem('total');
    }
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      localStorage.removeItem('cupom');
    };

    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  const cardFlag = (brand) => {
    if (brand === 'Mastercard')
      return <img alt="logo" src={MasterCardIcon} style={{ height: 40 }} />;
    if (brand === 'Visa') return <img alt="logo" src={VisaIcon} style={{ height: 40 }} />;
    if (brand === 'Elo') return <img alt="logo" src={EloIcon} style={{ height: 40 }} />;
  };

  function getCardFlag(value) {
    const cardnumber = value.replace(/[^0-9]+/g, '');

    const cards = {
      Visa: /^4[0-9]{15}$/,
      Mastercard:
        /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,
      // Amex: /^3[47][0-9]{13}/,
      Elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])/,
      // Aura: /^(5078\d{2})(\d{2})(\d{11})$/,
      // JCB: /^(?:2131|1800|35\d{3})\d{11}/,
      // Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      // Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      // Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/
    };

    for (var flag in cards) {
      if (cards[flag].test(cardnumber)) {
        return flag;
      }
    }

    if (cardnumber.length >= 16 && !flag) {
      return false;
    }
    return flag;
  }

  const handleChangeCardNumber = (e) => {
    const cardsBrand = getCardFlag(e.target.value);

    if (cardsBrand === false || e.target.value.replace(/\D/g, '').slice(0, 16).length !== 16) {
      setIsValidCardNumber(false);
    } else {
      setIsValidCardNumber(true);
    }

    setData({
      ...data,
      cardData: {
        ...data.cardData,
        cardNumber: e.target.value.slice(0, 19),
        brand: cardsBrand || '',
      },
    });
  };

  useEffect(() => {
    if (validity !== '') {
      setData({
        ...data,
        cardData: {
          ...data.cardData,
          expirationDate: validity,
        },
      });
    }
  }, [validity, data, setData]);

  const handleChange = (e) => {
    const { value } = e.target;

    // Remove qualquer caractere não numérico
    const cleanedValue = value.replace(/\D/g, '');

    // Formata o valor para seguir o padrão MM/YY
    let formattedValue = '';
    if (cleanedValue.length > 2) {
      formattedValue = `${cleanedValue.slice(0, 2)}/${cleanedValue.slice(2, 4)}`;
    } else {
      formattedValue = cleanedValue;
    }

    // Atualiza o estado com o valor formatado
    setValidity(formattedValue);
  };

  const handlePixGenerate = () => {
    setCurrentComponent('pixQrCode');
    sessionStorage.removeItem('leadId');
  };

  const leadIdToUse = sessionStorage.getItem('leadId');

  const applyCoupon = async () => {
    setLoadingCupom(true);

    let uId = null;

    if (!userInfo.triiboId.includes('@anonymous')) {
      uId = userInfo.uId;
    }

    try {
      if (leadIdToUse) {
        const result = await ApplydiscountCoupon(uId, leadIdToUse, cupom);
        const discount_total = result.response.discount_total;
        const total = result.response.total;

        localStorage.setItem('discount_total', discount_total);
        localStorage.setItem('total', total);
        localStorage.setItem('cupom', cupom);

        const discountValue = Number(discount_total).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });

        const totalValue = Number(total).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });

        toast.info(
          `Cupom adicionado. Desconto: ${discountValue}. Valor total do produto: ${totalValue}`
        );
        setLoadingCupom(false);
        trackEventMatomo('Pagamento: detalhes da compra', 'click', 'button', 'Aplicar cupom');
      } else {
        setLoadingCupom(false);
      }
    } catch (err) {
      toast.warning(err.error);
      setLoadingCupom(false);
    }
  };

  return (
    <>
      <DataContainer>
        <Title>{t('checkout.purchase_details')}</Title>
      </DataContainer>
      <DetailItem>
        <p>
          <span>{t('checkout.product_name')}:</span>
          {productData.name}
        </p>
      </DetailItem>
      <DetailItem>
        <p>
          <span>
            {totalValue === '' ? t('checkout.total_payment') + ':' : t('checkout.price') + ':'}
          </span>
          {Number(productData.price).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </p>
      </DetailItem>

      {/* cupom */}
      <S.Coupon>
        <TextField
          margin="normal"
          type="text"
          label={t('checkout.checkout_discount_code')}
          value={cupom}
          onChange={(e) => setCupom(e.target.value)}
          disabled={loadingCupom}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SellIcon />
              </InputAdornment>
            ),
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              applyCoupon();
            }
          }}
        />
        <Button
          variant="contained"
          size="large"
          onClick={applyCoupon}
          disabled={loadingCupom || cupom === ''}
        >
          {loadingCupom ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <CircularProgress size={30} />
            </div>
          ) : (
            t('checkout.apply')
          )}
        </Button>
      </S.Coupon>

      {cupomValue && (
        <>
          <DetailItem>
            <p>
              <span>{t('checkout.coupon_applied')}:</span>
              {Number(cupomValue).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p>
          </DetailItem>
          <DetailItem>
            <p>
              <span>{t('checkout.total_payment')}:</span>
              {Number(totalValue).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p>
          </DetailItem>
        </>
      )}

      <DataContainer>
        <Title>{t('checkout.payment')}</Title>

        <div>
          <Button
            onClick={() => setPaymentType('credit')}
            variant={paymentType === 'credit' ? 'contained' : 'outlined'}
            style={{ marginRight: 10 }}
          >
            {t('checkout.credit_card')}
          </Button>
          <Button
            onClick={() => setPaymentType('pix')}
            variant={paymentType === 'pix' ? 'contained' : 'outlined'}
          >
            {t('checkout.pix')}
          </Button>
        </div>

        {paymentType === 'credit' ? (
          <>
            <S.ObsCreditCard>
              <CreditCardOutlinedIcon />
              <span>{t('checkout.insert_card_data')}</span>
            </S.ObsCreditCard>
            <form onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                fullWidth
                required
                type="text"
                value={data.cardData.holder}
                onChange={(e) =>
                  setData({
                    ...data,
                    cardData: {
                      ...data.cardData,
                      holder: e.target.value,
                    },
                  })
                }
                label={t('checkout.card_holder')}
              />
              <TextField
                margin="normal"
                fullWidth
                required
                value={creditCardMask(data.cardData.cardNumber)}
                onChange={handleChangeCardNumber}
                error={!isValidCardNumber}
                helperText={!isValidCardNumber && t('checkout.invalid_card')}
                type="text"
                label={t('checkout.card_number')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{cardFlag(data.cardData.brand)}</InputAdornment>
                  ),
                }}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  type="tel"
                  label={t('checkout.card_validity')}
                  value={validity}
                  placeholder="MM/YY"
                  onChange={handleChange}
                  inputProps={{ maxLength: 5 }}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  type="text"
                  label={t('checkout.card_code')}
                  value={creditCardCVV}
                  onChange={(e) => setCreditCardCVV(e.target.value)}
                  inputProps={{ maxLength: 3 }}
                />
              </div>
              <Buttons>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={(e) => setCurrentComponent('personalData')}
                >
                  {t('general.back')}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading || !isValidCardNumber}
                >
                  {loading ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <CircularProgress size={30} />
                    </div>
                  ) : (
                    t('checkout.do_payment')
                  )}
                </Button>
              </Buttons>
            </form>
          </>
        ) : (
          <div
            className="height-pix"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              flexDirection: 'column',
            }}
          >
            <div>
              <S.ObsCreditCard style={{ marginBottom: '25px' }}>
                <PixIcon />
                <span>{t('checkout.pix_title')}</span>
              </S.ObsCreditCard>

              <Typography variant="subtitle1">
                <span dangerouslySetInnerHTML={{ __html: t('checkout.pix_description') }} />
              </Typography>
            </div>

            <div style={{ display: 'contents' }}>
              <Buttons>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={(e) => setCurrentComponent('personalData')}
                >
                  {t('general.back')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handlePixGenerate()}
                >
                  {t('checkout.pix_generate')}
                </Button>
              </Buttons>
            </div>
          </div>
        )}
      </DataContainer>
    </>
  );
}
