import React from 'react';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

import * as S from './styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function CanceledPayment() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="paper-container">
      <S.Container>
        <DoNotDisturbAltIcon
          sx={{
            fontSize: 80,
            color: '#D32F2F',
          }}
        />

        <h2>{t('checkout.payment_canceled')}</h2>
        <Button variant="contained" onClick={() => navigate('/')}>
          {t('checkout.home')}
        </Button>
      </S.Container>
    </div>
  );
}
