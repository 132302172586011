import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Scanner } from '@yudiel/react-qr-scanner';
import ReactInputMask from 'react-input-mask';

// STYLES
import * as S from './styles';
import {
  Container,
  TextField,
  Button,
  Typography,
  Divider,
  Box,
  CircularProgress,
  InputAdornment,
  Dialog,
} from '@mui/material';

// ICONS
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

// TRANSLATE
import { useTranslation } from 'react-i18next';

// SERVICES
import {
  colorText,
  convertDateDefault,
  findValueInArray,
  getLastUserInfo,
  maskCnpj,
  maskCPF,
  trackEventMatomo,
  trackEventMatomoElementId,
  trackEventMatomoVisit,
} from 'js/library/utils/helpers';
import phone from 'phone';
import phoneMaskArray from 'js/components/LoginForms/phoneMaskArray.json';

// COMPONENTS
import ActivateSubscription from './ActivateSubscription';

// HOOKS
import { SignUpContext } from 'js/context/SignUpContext';

// APIS
import { getUserPartner } from 'js/library/utils/API/apiGetUserPartner';
import checkProduct from 'js/library/utils/API/payment/apiCheckProduct';
import createLead from 'js/library/utils/API/payment/apiCreateLead';
import submitLeadPaymentCode from 'js/library/utils/API/payment/apiSubmitLeadPaymentCode';

export default function ActivationPassword() {
  const [colorPrimary, setColorPrimary] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);
  const [productId, setProductId] = useState('');

  const { infosSeuClube } = useContext(SignUpContext);
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const userInfo = getLastUserInfo();

  const { formFields } = useContext(SignUpContext);
  const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
  const partnerId = `${seuClubePersist.id}_${seuClubePersist.clubeId}`;

  const [dataSubscriptionInfo, setDataSubscriptionInfo] = useState({
    subscriptionInfo: {
      subscription_period: '',
      subscription_period_interval: '',
      subscription_length: '',
    },
  });

  useEffect(() => {
    trackEventMatomoVisit('Assinatura');
    document.title = 'Assinatura';
  }, []);

  useEffect(() => {
    if (infosSeuClube) {
      setColorPrimary(infosSeuClube.colorPrimary);
    }
  }, [infosSeuClube]);

  let uId = '';
  let userCellPhone = null;
  let userEmail = '';

  if (!userInfo.triiboId.includes('@anonymous')) {
    uId = userInfo.uId;

    try {
      userCellPhone = userInfo.contactList.find((i) => i.type === 'cellPhone').value;
    } catch {}
    try {
      userEmail = userInfo.contactList.find((i) => i.type === 'email').value;
    } catch {}
  }

  const [data, setData] = useState({
    cardData: {
      holder: '',
      brand: '',
      cardNumber: '',
      expirationDate: '',
    },
    addressData: {
      address_1: '',
      address_2: '',
      city: '',
      state: '',
      postcode: '',
      country: 'Brasil',
      neighborhood: '',
      number: '',
    },
    firstName: '',
    lastName: '',
    fone: userCellPhone,
    email: userEmail,
    confirm_email: userEmail,
  });

  const [productData, setProductData] = useState({
    productType: '',
    name: '',
    price: '',
    description: '',
    productImage: { src: '', alt: '' },
    id: null,
    quantity: 0,
  });

  useEffect(() => {
    let data = {};

    if (formFields.length > 0) {
      getUserPartner(partnerId, userInfo.uId).then((userInfo) => {
        formFields.forEach((field, index) => {
          switch (field?.type) {
            case 'root':
              if (field.fieldType === 'checkbox') {
                data = {
                  ...data,
                  [field.fieldName]: userInfo[field.fieldName] ? userInfo[field.fieldName] : false,
                };
              } else if (field.fieldType === 'date') {
                const date = new Date(userInfo[field.fieldName]);

                data = {
                  ...data,
                  [field.fieldName]: convertDateDefault(date),
                };
              } else {
                data = {
                  ...data,
                  [field.fieldName]: userInfo[field.fieldName] ? userInfo[field.fieldName] : '',
                };
              }
              break;

            case 'addressList':
              if (userInfo?.addressList?.length > 0) {
                const address = userInfo?.addressList[0];

                address
                  ? (data = { ...data, addressList: { [field.fieldName]: address } })
                  : (data = { ...data, addressList: { [field.fieldName]: {} } });
              }
              break;

            case 'contactList':
              if (field.fieldType === 'email') {
                if (findValueInArray(userInfo.contactList, 'type', field.fieldName)) {
                  const dataEmail = findValueInArray(
                    userInfo.contactList,
                    'type',
                    field.fieldName
                  ).value;
                  data = {
                    ...data,
                    contactList: { ...data.contactList, [field.fieldName]: dataEmail },
                  };
                } else {
                  data = {
                    ...data,
                    contactList: { ...data.contactList, [field.fieldName]: '' },
                  };
                }
              } else if (field.fieldType === 'cellPhone') {
                if (findValueInArray(userInfo.contactList, 'type', field.fieldType)) {
                  const dataCellPhone = phone(
                    findValueInArray(userInfo.contactList, 'type', field.fieldType).value
                  );

                  const maskFilter = phoneMaskArray.filter(
                    (mask) => mask.ddd === dataCellPhone.countryCode
                  );

                  data = {
                    ...data,
                    contactList: {
                      ...data.contactList,
                      [field.fieldName]: {
                        mask: { ...maskFilter[0] },
                        phone: dataCellPhone.phoneNumber.split(maskFilter[0].ddd)[1],
                      },
                    },
                  };
                }
              } else {
                data = {
                  ...data,
                  contactList: {
                    ...data.contactList,
                    [field.fieldName]: findValueInArray(
                      userInfo.contactList,
                      'type',
                      field.fieldName
                    ).value,
                  },
                };
              }
              break;

            case 'documentList':
              if (field.fieldType === 'cpf') {
                if (findValueInArray(userInfo.documentList, 'type', field.fieldName)) {
                  data = {
                    ...data,
                    documentList: {
                      ...data.documentList,
                      [field.fieldName]: maskCPF(
                        findValueInArray(userInfo.documentList, 'type', field.fieldName).value
                      ),
                    },
                  };
                }
              } else if (field.fieldType === 'cnpj') {
                if (findValueInArray(userInfo.documentList, 'type', field.fieldName)) {
                  data = {
                    ...data,
                    documentList: {
                      ...data.documentList,
                      [field.fieldName]: maskCnpj(
                        findValueInArray(userInfo.documentList, 'type', field.fieldName).value
                      ),
                    },
                  };
                }
              } else {
                data = {
                  ...data,
                  documentList: {
                    ...data.documentList,
                    [field?.fieldName]: findValueInArray(
                      userInfo?.documentList,
                      'type',
                      field?.fieldType
                    )?.value,
                  },
                };
              }
              break;

            case 'optInList': {
              const optIn = `optIn_${field.optInId !== undefined ? field.optInId : ''}`;
              data = {
                ...data,
                optInList: {
                  ...data.optInList,
                  [optIn]: {
                    accept: findValueInArray(userInfo.optInList, 'optInId', field.optInId)
                      ? findValueInArray(userInfo.optInList, 'optInId', field.optInId).accept
                      : false,
                  },
                },
              };
              break;
            }

            case 'others':
              if (field.inputType === 'date') {
                const date = new Date(userInfo[field.fieldName]);
                data = {
                  ...data,
                  [field.fieldName]: convertDateDefault(date),
                };
              } else if (field.inputType === 'checkbox') {
                data = {
                  ...data,
                  [field.fieldName]: { accept: userInfo[field.fieldName] },
                };
              } else {
                data = {
                  ...data,
                  [field.fieldName]: userInfo[field.fieldName],
                };
              }
              break;

            default:
              break;
          }
          if (index === formFields.length - 1) {
            setData((prevState) => ({
              ...prevState,
              firstName: data.firstName || data.firstname || '',
              lastName: data.lastName || data.lastname || '',
              addressData: {
                ...prevState.addressData,
                address_1: data.addressList?.endereco.streetAve,
                address_2: data.addressList?.endereco.extra,
                city: data.addressList?.endereco.city,
                state: data.addressList?.endereco.state,
                postcode: data.addressList?.endereco.zipCode,
                neighborhood: data.addressList?.endereco.neighborhood,
                number: data.addressList?.endereco.streetNumber,
              },
            }));
          }
        });
      });
    }
  }, [formFields, formFields.length, partnerId, userInfo.uId]);

  const urlFull = document.location.pathname + document.location.search;
  const url = searchParams.get('productId');

  useEffect(() => {
    if (userInfo.triiboId.includes('@anonymous')) {
      sessionStorage.setItem('loginOption', '3');
      sessionStorage.setItem('loginRedirect', urlFull);
      navigate('/validarAcesso');
    } else if (url) {
      setProductId(url);
    } else {
      toast.error(t('checkout.unidentified_payment'));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (productId !== '') {
      const userInfo = getLastUserInfo();
      let uId = null;

      if (!userInfo.triiboId.includes('@anonymous')) {
        uId = userInfo.uId;
      }

      //removendo dado de assinatura local para ter jornada limpa
      sessionStorage.removeItem('subscriptionProfile');

      checkProduct(uId, productId)
        .then((res) => {
          let productType;

          const metaDataArray = res.response.meta_data;

          const subscriptionData = {
            subscription_period: '',
            subscription_period_interval: '',
            subscription_length: '',
          };

          if (res.response.type === 'subscription') {
            if (res.response.profile) {
              sessionStorage.setItem('subscriptionProfile', res.response.profile);
            }

            productType = 'subscription';

            metaDataArray.forEach((item) => {
              if (item.key === '_subscription_period') {
                subscriptionData.subscription_period = item.value;
              } else if (item.key === '_subscription_period_interval') {
                subscriptionData.subscription_period_interval = item.value;
              } else if (item.key === '_subscription_length') {
                subscriptionData.subscription_length = item.value;
              }
            });

            // Atualiza o estado com os valores filtrados
            setDataSubscriptionInfo((prevState) => ({
              ...prevState,
              subscriptionInfo: subscriptionData,
            }));
          } else if (res.response.virtual) {
            productType = 'digital';
          } else {
            productType = 'physical';
          }

          setProductData({
            name: res.response.name,
            description: res.response.description,
            price: res.response.price,
            productImage: res.response.images.length > 0 ? res.response.images[0] : '',
            productType: productType,
            id: productId,
            quantity: 1,
          });
        })
        .catch((error) => {
          console.error('Ocorreu um erro na checkProduct: ', error);
          setProductData(null);
          toast.error(t('checkout.couldnt_load_product'));
        });
    }
  }, [t, productId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let lead = null;

    const firstName = data.firstName;
    const lastName = data.lastName;

    if (!firstName || !lastName) {
      toast.error(
        'Nome e sobrenome são necessários para continuar. Acesse seu perfil e complete seu cadastro para continuar.'
      );
      setLoading(false);
    } else {
      if (!lead) {
        let obj = {
          uId,
          firstName,
          lastName,
          fone: data.fone,
          email: data.email,
          productType: productData.productType,
          productId: productData.id,
          quantity: productData.quantity,
        };

        if (productData.productType === 'physical') {
          obj = {
            ...obj,
            address_1: data.addressData.address_1,
            address_2: data.addressData.address_2,
            city: data.addressData.city,
            state: data.addressData.state,
            postcode: data.addressData.postcode,
            country: data.addressData.country,
            neighborhood: data.addressData.neighborhood,
            number: data.addressData.number,
          };
        }

        if (productData.productType === 'subscription') {
          obj = {
            ...obj,
            ...dataSubscriptionInfo,
          };
        }

        try {
          const leadData = await createLead(obj);
          lead = leadData.response.lead;
        } catch (error) {
          console.error(t('errors.try_later'));
        }
      }

      try {
        if (lead.id) {
          const voucherId = code;
          const submitPayment = await submitLeadPaymentCode(uId, lead.id, voucherId);
          const paymentStatus = submitPayment.payment.status;

          if (paymentStatus === 1 || paymentStatus === 2 || paymentStatus === 20) {
            // Se o status for "Authorized", "PaymentConfirmed" ou "Scheduled"
            setLoading(false);
            if (userInfo.triiboId.includes('@anonymous')) {
              sessionStorage.setItem('@user-payment-email', data.email);
            } else {
              const subscriptionProfile = sessionStorage.getItem('subscriptionProfile');

              if (subscriptionProfile) {
                let tempUserInfo = userInfo;
                tempUserInfo.userProfile = subscriptionProfile;

                localStorage.setItem(
                  'userInfoAuxiliar',
                  JSON.stringify({
                    ...tempUserInfo,
                  })
                );
              }

              trackEventMatomoElementId('AtivarAssinatura', 'click', 'button', `${productData.id}`);

              navigate('/assinatura-confirmada');
            }
          } else {
            setLoading(false);
            if (paymentStatus === 3) {
              // Pagamento recusado pelo cartão
              toast.error(t('checkout.payment_refused'));
            } else {
              // Outro erro, possível erro interno
              toast.error(t('checkout.payment_error'));
            }
          }
        }
      } catch (error) {
        console.error('Erro na submitLeadPaymentCode: ', error);
        setLoading(false);
        toast.error(t('checkout.payment_error'));
      }
    }
  };

  // Função para abrir e fechar o scanner
  const handleScanQRCode = () => {
    setOpenScanner(true);
  };

  const handleScan = (result) => {
    if (result && result.length > 0) {
      const qrValue = result[0].rawValue;
      setCode(qrValue);
      setOpenScanner(false);

      trackEventMatomo('Assinatura', 'click', 'button', 'Escanear qr code');
    }
  };

  return userInfo.userProfile ? (
    <ActivateSubscription />
  ) : (
    <div className="paper-container">
      <S.Container>
        {productId === '' && (
          <Typography variant="subtitle1" color="error">
            {t('checkout.unidentified_payment')}
          </Typography>
        )}

        <Container sx={{ width: '100%', padding: '0px', margin: '9px' }}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bolder',
              width: '85%',
            }}
          >
            {t('checkout.subscription_activation_code')}
          </Typography>

          <Divider color="secondary" style={{ margin: '10px 0px' }} />

          <ReactInputMask
            mask="M01-L**-*******-**"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            maskChar={null}
          >
            {() => (
              <TextField
                label={t('checkout.insert_code')}
                variant="outlined"
                fullWidth
                margin="normal"
                type="text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        sx={{ padding: '5px !important', minWidth: 'max-content' }}
                        onClick={handleScanQRCode}
                      >
                        <QrCodeScannerIcon />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </ReactInputMask>

          {/* Modal para exibir o leitor de QR code */}
          <Dialog open={openScanner} onClose={() => setOpenScanner(false)} maxWidth="sm" fullWidth>
            <div style={{ padding: '20px', textAlign: 'center' }}>
              <h2>Escaneie o QR Code</h2>
              <Scanner
                onScan={handleScan}
                onError={(error) => console.error('Erro ao acessar a câmera:', error)}
                constraints={{ facingMode: 'environment' }}
                style={{ width: '100%' }}
              />
            </div>
          </Dialog>
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Button
              variant="contained"
              disabled={code.length < 18 || loading || productId === ''}
              sx={{
                minWidth: '300px',
                mt: 2,
                backgroundColor: colorPrimary,
                color: colorText(colorPrimary),
                '&:hover': {
                  backgroundColor: colorPrimary,
                },
              }}
              onClick={handleSubmit}
            >
              {loading ? (
                <CircularProgress size={20} sx={{ color: colorText(colorPrimary) }} />
              ) : (
                t('checkout.button_code')
              )}
            </Button>
          </Box>
        </Container>
      </S.Container>
    </div>
  );
}
