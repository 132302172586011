export default function reducer(state = {
  userInfo: null
}, action) {

  switch (action.type) {

    case 'USER_FORM_PENDING':
      {
        return {
          ...state,
          userInfo: null
        };
      }

    case 'USER_FORM_FULFILLED':
      {
        return {
          ...state,
          userInfo: action.payload.userInfo
        };
      }

    case 'USER_FORM_REJECTED':
      {
        return {
          ...state,
          userInfo: null
        };
      }

    default:
      return state;

  }

}
