import { Grid } from '@mui/material';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  bottom: 0;
  padding: 1.9rem 0;
  position: relative;
  width: 100%;
  z-index: 1;

  ul {
    display: flex;
    align-items: center;

    flex-flow: wrap;
    list-style: none;
    margin: 0 -10px;
    min-height: 35px;
    padding: 0;

    li {
      float: left;
      padding: 0 10px;
    }
  }

  ul::after,
  ul::before {
    clear: both;
    content: '';
    display: table;
  }

  @media screen and (max-width: 959px) {
    display: none;

    ul {
      margin-bottom: 1rem;

      li {
        width: 100%;
      }
    }
  }
`;

export const GridLayout = styled(Grid)`
  display: ${(props) => props.display};
  align-items: ${(props) => props.align};
  padding: ${(props) => props.padding};
`;

