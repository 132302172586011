import { styled } from 'styled-components';

export const ObsCreditCard = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 0.5rem 0rem;

  > span {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    color: #343434;
  }
`;

export const Coupon = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;

  @media (max-width: 600px) {
    flex-direction: column;

    > button {
      margin-bottom: 2rem;
    }
  }
`;
