import React, { useState, useEffect } from 'react';

import * as S from './styles';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { colorText, trackEventMatomo } from 'js/library/utils/helpers';
import { Bell, BellRinging } from 'phosphor-react';

export default React.memo(function BellNotificationMenu(props) {
  const { notificationIsOpen, feedPosts, handleNotificationCloser, colorPrimary } = props;
  const [hasNewFeed, setHasNewFeed] = useState(false);

  useEffect(() => {
    const feedReaded = localStorage.getItem('feedReaded');
    let mostNewFeed = {};

    if (feedPosts) mostNewFeed = feedPosts[0];

    //verifica se tem novidades
    if (feedPosts.length > 0 && (!feedReaded || feedReaded < mostNewFeed?.creationDate)) {
      setHasNewFeed(true);

      setTimeout(() => {
        setHasNewFeed(false);
      }, 30000);
    }
  }, [feedPosts]);

  return (
    <>
      <S.NotificationCircle
        $colorPrimary={colorPrimary}
        $colorSecondary={colorText(colorPrimary)}
        $hasNewFeed={hasNewFeed}
        onClick={(e) => {
          const date = new Date();
          e.preventDefault();
          setHasNewFeed(false);
          handleNotificationCloser(notificationIsOpen);
          localStorage.setItem('feedReaded', date.getTime());
          trackEventMatomo(
            'menu',
            'click',
            'button',
            `notification-${!notificationIsOpen ? 'open' : 'close'}`
          );
        }}
      >
        <S.NotificationIconContainer>
          {notificationIsOpen ? (
            <CloseRoundedIcon
              style={{
                width: 'auto',
                height: 'auto',
                color: colorText(colorPrimary),
              }}
            />
          ) : hasNewFeed ? (
            <BellRinging
              weight="fill"
              color={colorText(colorPrimary)}
              style={{ width: '100%', height: 'auto' }}
            />
          ) : (
            <Bell
              weight="fill"
              color={colorText(colorPrimary)}
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </S.NotificationIconContainer>
      </S.NotificationCircle>
    </>
  );
});
