import React from 'react';

import { NotificationItem } from './styles';

import { formatDate } from 'js/library/utils/helpers';
import { Typography } from '@mui/material';

const NotificationBody = (props) => {
  const { title, description, date, lida } = props;

  return (
    <NotificationItem lida={lida}>
      <Typography variant="h6">
        <b>{title ? title : 'Notificação'}</b>
      </Typography>
      <Typography variant="body1" style={{ fontSize: 12, whiteSpace: 'pre-line' }}>
        {description ? description : 'Sem descrição'}
      </Typography>
      <Typography variant="body2" style={{ fontSize: 10, marginTop: 15 }}>
        {date ? formatDate(date) : '99/99/9999'}
      </Typography>
    </NotificationItem>
  );
};

export default NotificationBody;

