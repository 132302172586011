import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import GoBack from 'js/containers/GoBack.js';

import faqImage from 'styles/assets/faq.png';
import alertImage from 'styles/assets/Messages/Alert.png';
import promotionImage from 'styles/assets/Messages/Promotion.png';
import triiboManiaImage from 'styles/assets/Messages/Triibomania.png';
import newsImage from 'styles/assets/Messages/News.png';
import establishmentImage from 'styles/assets/Messages/Establishment.png';

import { trackEventMatomoVisit } from 'js/library/utils/helpers';

export default function HowToUse() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'Como usar';
    trackEventMatomoVisit('Como usar');
  }, []);

  return (
    <div className="paper-container" style={{ fontSize: 15 }}>
      <GoBack />

      <img
        src={faqImage}
        alt="faq"
        style={{ maxWidth: '100%', margin: '1em auto', display: 'block' }}
      />

      {/* TROCAR */}
      <Typography variant="h6" style={{ lineHeight: 'initial', fontWeight: 'bolder' }}>
        {t('how_it_works.how_to_share_the_app')}
      </Typography>
      <Typography variant="body1" style={{ fontSize: 15 }}>
        {t('how_it_works.how_to_share_the_app_answer')}
      </Typography>

      <br />

      {/* TROCAR */}
      <Typography variant="h6" style={{ lineHeight: 'initial', fontWeight: 'bolder' }}>
        {t('how_it_works.how_promos_work')}
      </Typography>
      <Typography variant="body1" style={{ fontSize: 15 }}>
        {t('how_it_works.how_promos_work_answer')}
      </Typography>

      <br />

      {/* TROCAR */}
      <Typography variant="h6" style={{ lineHeight: 'initial', fontWeight: 'bolder' }}>
        {t('how_it_works.what_are_qr_codes_for')}
      </Typography>
      <Typography variant="body1" style={{ fontSize: 15 }}>
        {t('how_it_works.what_are_qr_codes_for_answer')}
      </Typography>

      <br />

      {/* TROCAR */}
      <Typography variant="h6" style={{ lineHeight: 'initial', fontWeight: 'bolder' }}>
        {t('how_it_works.how_to_find_establishment')}
      </Typography>
      <Typography variant="body1" style={{ fontSize: 15 }}>
        {t('how_it_works.how_to_find_establishment_first_answer')}
      </Typography>
      <Typography variant="body1" style={{ fontSize: 15 }}>
        {t('how_it_works.how_to_find_establishment_second_answer')}
      </Typography>

      <br />

      {/* TROCAR */}
      <Typography variant="h6" style={{ lineHeight: 'initial', fontWeight: 'bolder' }}>
        {t('how_it_works.where_to_find_proof_of_purchase')}
      </Typography>
      <Typography variant="body1" style={{ fontSize: 15 }}>
        {t('how_it_works.where_to_find_proof_of_purchase_answer')}
      </Typography>

      <br />

      {/* TROCAR */}
      <Typography variant="h6" style={{ lineHeight: 'initial', fontWeight: 'bolder' }}>
        {t('how_it_works.how_wifi_work')}
      </Typography>
      <Typography variant="body1" style={{ fontSize: 15 }}>
        {t('how_it_works.how_wifi_work_answer')}
      </Typography>

      <br />

      {/* TROCAR */}
      <Typography variant="h6" style={{ lineHeight: 'initial', fontWeight: 'bolder' }}>
        {t('how_it_works.how_to_get_triibo_points')}
      </Typography>
      <Typography variant="body1" style={{ fontSize: 15 }}>
        {t('how_it_works.how_to_get_triibo_points_first_answer')}
      </Typography>
      <br />
      <Typography variant="body1" style={{ fontSize: 15 }}>
        {t('how_it_works.how_to_get_triibo_points_second_answer')}
      </Typography>

      <br />

      {/* TROCAR */}
      <Typography variant="body1" style={{ fontSize: 12 }}>
        {t('how_it_works.how_to_get_triibo_points_*')}
      </Typography>

      <br />

      {/* TROCAR */}
      <Typography variant="body1" style={{ fontSize: 12 }}>
        {t('how_it_works.how_to_get_triibo_points_**')}
      </Typography>

      <br />

      {/* TROCAR */}
      <Typography variant="h6" style={{ lineHeight: 'initial', fontWeight: 'bolder' }}>
        {t('how_it_works.what_do_the_icons_mean')}
      </Typography>
      <Grid
        container
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Grid item sm={4} xs={6} style={{ margin: '1em 0' }}>
          <img
            src={alertImage}
            alt="alerta"
            style={{
              maxWidth: '100%',
              margin: '0 auto 5px auto',
              display: 'block',
            }}
          />
          <b>{t('how_it_works.alert')}</b>
        </Grid>
        <Grid item sm={4} xs={6} style={{ margin: '1em 0' }}>
          <img
            src={promotionImage}
            alt="promoção"
            style={{
              maxWidth: '100%',
              margin: '0 auto 5px auto',
              display: 'block',
            }}
          />
          <b>{t('how_it_works.promotion')}</b>
        </Grid>
        <Grid item sm={4} xs={6} style={{ margin: '1em 0' }}>
          <img
            src={triiboManiaImage}
            alt="vitriine"
            style={{
              maxWidth: '100%',
              margin: '0 auto 5px auto',
              display: 'block',
            }}
          />
          <b>{t('how_it_works.showcase')}</b>
        </Grid>
        <Grid item sm={4} xs={6} style={{ margin: '1em 0' }}>
          <img
            src={newsImage}
            alt="novidade"
            style={{
              maxWidth: '100%',
              margin: '0 auto 5px auto',
              display: 'block',
            }}
          />
          <b>{t('how_it_works.new')}</b>
        </Grid>
        <Grid item sm={4} xs={6} style={{ margin: '1em 0' }}>
          <img
            src={establishmentImage}
            alt="estabelecimento"
            style={{
              maxWidth: '100%',
              margin: '0 auto 5px auto',
              display: 'block',
            }}
          />
          <b>{t('how_it_works.establishment')}</b>
        </Grid>
      </Grid>
    </div>
  );
}
