import styled from 'styled-components';

const NotificationContainer = styled.span`
  background: transparent;
  z-index: 1;
  display: flex;

  // @media (max-width: 900px) {
  //       padding: 45px;
  // }
`;

export const NotificationContainerMobile = styled(NotificationContainer)`
  top: 13px;
  right: 15px;
  height: 3rem;
  width: 3rem;
`;

export const NotificationContainerDesktop = styled(NotificationContainer)`
  top: -130px;
  right: 4rem;
  height: 5rem;
  width: 5rem;
`;

export const NotificationNoneFound = styled.div`
  width: 100%;
  height: 2rem;
  color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NotificationCircle = styled.div`
  border-radius: 50%;
  background: ${(props) => props.$colorPrimary};
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.$colorSecondary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  transition: 0.5s;

  ${({ $hasNewFeed }) =>
    $hasNewFeed &&
    `

  animation: shake 2s ease-in-out infinite;
  
  @keyframes shake {
    0% {
        transform: rotate(-20deg) scale(1);
    }
    15% {
      transform: rotate(-20deg) scale(1.2);
    }
    30% {
        transform: rotate(20deg) scale(1.2);
    }
    45% {
      transform: rotate(-20deg) scale(1.2);
    }
    70% {
        transform: rotate(20deg) scale(1.2);
    }
    100% {
        transform: rotate(-20deg) scale(1);
    }
  }
    `}
`;

export const NotificationIconContainer = styled.span`
  height: 90%;
  width: 90%;
`;

export const NotificationDisplayContainer = styled.div`
  max-height: 0;
  position: absolute;
  width: 30vw;
  right: 145px;
  top: 20px;
  background: white;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 0px 28px -6px rgba(0, 0, 0, 0.75);
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 0;
  border-radius: 10px;

  @media (max-width: 1880px) {
    right: 135px;
  }

  @media (max-width: 1835px) {
    right: 125px;
  }

  @media (max-width: 1800px) {
    right: 115px;
  }

  @media (max-width: 1760px) {
    right: 105px;
  }

  @media (max-width: 1725px) {
    right: 95px;
  }

  @media (max-width: 1680px) {
    right: 85px;
  }

  @media (max-width: 1640px) {
    right: 75px;
  }

  @media (max-width: 1600px) {
    right: 65px;
  }

  @media (max-width: 1560px) {
    right: 55px;
  }

  @media (max-width: 1520px) {
    right: 45px;
  }

  @media (max-width: 1480px) {
    right: 35px;
  }

  @media (max-width: 1230px) {
    width: 45vw;
  }

  @media (max-width: 960px) {
    position: fixed;
    width: 100vw !important;
    top: 0px;
    right: 0px;
    border-radius: 0px;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    `
        max-height: 100vh;

        @media (min-width: 960px) {
          max-height: 96vh;
        }
        `}
`;

export const NotificationItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.colorPrimary};
  padding: 10px;
  text-align: left;
  min-height: 100px;
  width: 95%;
  color: #222;
  cursor: auto;

  ${({ lida }) =>
    lida &&
    `
      background: #F7F7F7;
      border-bottom: 1px solid #dadada;
    `}
`;

