import cfac22 from 'js/library/utils/cfac22/cfac22';
import axios from 'axios';
import { hashTriibo1, validateRegistrationDate, generateRandom } from 'js/library/utils/helpers.js';

export function getChannelToken_v1(uId) {
  return new Promise((resolve, reject) => {
    //capturando dados do Storage
    const channelObj = JSON.parse(localStorage.getItem('channelToken'));

    const valideDate = validateRegistrationDate(
      channelObj === null ? null : channelObj.registrationDate,
      15
    );
    const channelUId = channelObj === null ? null : channelObj.uId;

    //token não existe no storage
    if (
      channelObj === null ||
      channelObj.id === null ||
      channelObj.id === '' ||
      channelObj.id === undefined ||
      !valideDate ||
      channelUId === undefined ||
      channelUId === null ||
      channelUId !== uId
    ) {
      const passPhrase = generateRandom(10);
      const challenge = hashTriibo1(cfac22('apiToken'), passPhrase, cfac22('channeltoken'));

      axios
        .post(cfac22('API_HOST_V1') + 'getChannelToken_v1', {
          triiboHeader: {
            apiToken: cfac22('apiToken'),
            channelGroup: cfac22('channelGroup'),
            channelName: cfac22('channelName'),
            uId: uId,
            passPhrase: passPhrase,
            challenge: challenge,
            channelId: cfac22('channelId'),
          },
        })
        .then(() => {
          //console.log('RESULTADO GET CHANNEL TOKEN', result);
          localStorage.setItem(
            'channelToken',
            '{ "id":"' +
              challenge +
              '", "registrationDate":' +
              Date.now() +
              ', "uId":"' +
              uId +
              '" }'
          );

          resolve(challenge);
        })
        .catch((error) => {
          //console.log('ERRO GET CHANNEL TOKEN', error);
          reject(error);
        });
    }
    //token existe no storage
    else {
      resolve(channelObj.id);
    }
  });
}
