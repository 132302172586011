import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import Loading from 'js/containers/Loading/Loading';
import { useSearchParams } from 'react-router-dom';
import { validateUserPartner } from 'js/library/utils/API/apiValidateUserPartner';
import { getUserPartner } from 'js/library/utils/API/apiGetUserPartner';
import { preRegisters } from 'js/library/utils/API/preRegisters';
import { checkAuth } from 'js/library/utils/API/checkAuth';
import { getClubIdEnvironment, isValidURL } from 'js/library/utils/helpers';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import { loginUser } from 'js/library/services/AuthenticationManager';

import { Button, Typography } from '@mui/material';

export default function AutoLogin() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const authToken = searchParams.get('authToken');
  const userToken = decodeURIComponent(searchParams.get('userToken'));
  const userCellphone = searchParams.get('userCellphone');
  const url = decodeURIComponent(searchParams.get('url'));
  const force = searchParams.get('force');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      async function logUser(clubeInfo, isUser) {
        const userInfo = await getUserPartner(clubeInfo.partnerId, isUser.uId);

        await loginUser(userInfo.triiboId.replace(/[,]/gi, '.'), userInfo.passPhrase);
        localStorage.setItem(
          'userInfoAuxiliar',
          JSON.stringify({
            ...userInfo,
            triiboId: userInfo.triiboId,
            uId: isUser.uId,
          })
        );

        return navigate(isValidURL(url) ? url : '/');
      }

      async function getData() {
        let validateToken = null;

        try {
          validateToken = await checkAuth(authToken);
        } catch (error) {
          // console.log(error);
        }

        if (validateToken && validateToken.isValid) {
          const clubeId = getClubIdEnvironment();

          //buscando partnerId
          const clubeInfo = await getSeuClube(clubeId);

          //verificando se usuário existe no clube
          const isUser = await validateUserPartner(userToken, clubeInfo.partnerId);

          if (isUser.user) {
            //usuario existe, enviar user logado

            logUser(clubeInfo, isUser);
          } else {
            //usuario não existe, tratar conforme params
            if (force) {
              //forçar cadastro do usuário
              try {
                await preRegisters(clubeId, clubeInfo.partnerId, clubeId, userToken, userCellphone);
                logUser(clubeInfo, isUser);
              } catch (error) {
                setLoading(false);
              }
            } else {
              //inserir na whitelist e enviar para tela de cadastro
              if (!isUser.isEnabled) {
                try {
                  await preRegisters(clubeId, clubeInfo.partnerId, clubeId, userToken, null);
                  navigate('/validarAcesso?userToken=' + userToken);
                } catch (error) {
                  setLoading(false);
                }
              } else navigate('/validarAcesso?userToken=' + userToken);
            }
          }
        } else {
          setLoading(false);
        }
      }

      if (loading) getData();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [force, navigate, url, userToken, loading, userCellphone, authToken]);

  if (loading)
    return (
      <div className="loading-login">
        <Loading />
      </div>
    );
  return (
    <div style={{ padding: 10, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        {t('errors.try_later')}
      </Typography>
      <div>
        <Link to="/">
          <Button variant="contained" color="primary" style={{ textTransform: 'none' }}>
            Voltar para o clube
          </Button>
        </Link>
      </div>
    </div>
  );
}
