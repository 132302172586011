import React, { useEffect } from 'react';

import { isValidURL, trackEventMatomoVisit } from 'js/library/utils/helpers';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function FrameFullPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let url = decodeURIComponent(searchParams.get('url'));

  if (url.indexOf('https://') < 0 && url.indexOf('http://') < 0) {
    url = 'https://' + url;
  }

  useEffect(() => {
    trackEventMatomoVisit('Página de visualização de frame');

    if (!isValidURL(url)) {
      return navigate('/');
    }
  }, [navigate, url]);

  return (
    <div>
      <iframe
        title="Site in frame"
        src={url}
        style={{ minHeight: '95vh', minWidth: '100%', border: 'thin', verticalAlign: 'text-top' }}
        className={
          window.location.pathname.replace(/\//g, '') === 'pagina-interna'
            ? 'padding-iframe-interno'
            : 'padding-iframe-completo'
        }
      />
    </div>
  );
}

