import styled from 'styled-components';

export const NpsModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  > span {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }

  @media (max-width: 405px) {
    align-items: center;
    justify-content: center;
  }
`;

export const SlideContainer = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  column-gap: 80px;
  @media screen and (max-width: 388px) {
    column-gap: 50px;
  }
  justify-content: center;

  .slide-arrow {
    position: relative !important;
    margin-top: -10px;

    &:after {
      color: rgb(255, 117, 7);
      font-size: 32px;
    }
  }
`;

export const NpsRatingContainer = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 50px 0;

  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }

  .container {
    max-width: 100%;
    padding: 4rem 1rem;
    margin: 0 auto;
  }
  .swiper-slide {
    width: 80px;
    position: relative;
  }
`;

export const NpsItemRating = styled.li`
  width: 80px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${({ color }) => color};
  border-radius: 3px;
  cursor: pointer;

  p {
    font-size: 24px;
    font-weight: bolder;
    text-shadow: 0px 0px 7px #000000;
  }
`;

