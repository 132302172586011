import { getNpsForm } from 'js/library/utils/API/getNpsForm';
import { answerNpsForm } from 'js/library/utils/API/answerNpsForm';

export const fetchNpsQuestions = async (userInfo) => {
  const clubeId = JSON.parse(sessionStorage.getItem('seuClubeInfos'))?.partnerId;
  const response = await getNpsForm(userInfo.uId, clubeId);
  return response.success ? response.response : null;
};

export const submitNpsForm = async (userInfo, answers, isChecked) => {
  const clubId = JSON.parse(sessionStorage.getItem('seuClubeInfos'))?.partnerId;
  let data = {
    clubId,
    answers,
    postpone: false,
  };
  if (isChecked) data = { clubId, postpone: true };

  const response = await answerNpsForm(userInfo?.uId, data);

  return response;
};

export const numbersNPS = [
  {
    number: 0,
    color: 'rgb(196 1 22)',
  },
  {
    number: 1,
    color: 'rgb(228 3 9)',
  },
  {
    number: 2,
    color: 'rgb(252 50 4)',
  },

  {
    number: 3,
    color: 'rgb(253 98 1)',
  },
  {
    number: 4,
    color: 'rgb(251 173 11)',
  },
  {
    number: 5,
    color: 'rgb(255 214 7)',
  },
  {
    number: 6,
    color: 'rgb(223 239 11)',
  },
  {
    number: 7,
    color: 'rgb(217 240 12)',
  },
  {
    number: 8,
    color: 'rgb(189 236 1)',
  },
  {
    number: 9,
    color: 'rgb(155 223 31)',
  },
  {
    number: 10,
    color: 'rgb(56 190 66)',
  },
];
