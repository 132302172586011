import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { Grid, Typography, CircularProgress, Tabs, Tab, Modal, Button } from '@mui/material';

import BalanceGridActive from 'js/containers/Balance/BalanceGridActive';
import BalanceGridInactive from 'js/containers/Balance/BalanceGridInactive';

import {
  getLastUserInfo,
  trackEventMatomoVisit,
  trackEventMatomo,
  getClubIdEnvironment,
  colorText,
} from 'js/library/utils/helpers';
import { getWallet_v1 } from 'js/library/utils/API/getWallet_v1.js';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import StatementModal from 'js/containers/Balance/StatementModal';

export default function Wallet() {
  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
  const userInfo = getLastUserInfo();

  const [tab, setTab] = useState(0);
  const [openStatement, setOpenStatement] = useState(false);
  const [activeCoupons, setActiveCoupons] = useState(null);
  const [inactiveCoupons, setInactiveCoupons] = useState(null);
  const [hiddenExtract, setHiddenExtract] = useState(true);
  const isAnonymous = userInfo.triiboId.includes('anonymous');

  useEffect(() => {
    trackEventMatomoVisit('Carteira');
    document.title = 'Carteira';

    if (!isAnonymous) {
      const clubeId = getClubIdEnvironment();
      if (clubeId) {
        getSeuClube(clubeId).then((dataClube) => {
          if (dataClube?.showScore) {
            setHiddenExtract(false);
          }
        });
      }

      getWallet_v1(userInfo.uId).then((walletReceived) => {
        if (walletReceived != null) {
          const activeCouponsAux = [];
          const inactiveCouponsAux = [];

          if (walletReceived.coupons !== null) {
            Object.entries(walletReceived.coupons).forEach((coupon) => {
              const item = coupon[1];
              item.key = coupon[0];

              if (item.status === 'enviado' || item.state === 'ongoing') {
                activeCouponsAux.push(item);
              } else {
                inactiveCouponsAux.push(item);
              }
            });
          }

          setActiveCoupons(activeCouponsAux);
          setInactiveCoupons(inactiveCouponsAux);
        }
      });
    }
  }, []);

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  if (isAnonymous) {
    return <Navigate to="/login" />;
  } else {
    return (
      <div className="paper-container">
        <div style={{ width: '100%' }}>
          <Modal
            sx={{ zIndex: 9999999 }}
            open={openStatement}
            onClose={() => setOpenStatement(false)}
          >
            <div>
              <StatementModal
                openStatement={openStatement}
                setOpenStatement={setOpenStatement}
                userInfo={userInfo}
              />
            </div>
          </Modal>
          <Grid
            container
            sx={{
              backgroundColor: '#fff',
              width: '100%',
              maxWidth: 1600,
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'center',
              padding: {
                xs: '10px',
                md: '20px',
              },
            }}
          >
            <Grid item xs={0} sm={5}></Grid>
            <Grid item xs={12} sm={7}>
              {!hiddenExtract && (
                <Button
                  onClick={() => setOpenStatement(true)}
                  variant="contained"
                  sx={{
                    width: '100%',
                    margin: '15px 0px',
                    color: colorText(infoSeuClube.colorPrimary),
                    background: infoSeuClube.colorPrimary,
                  }}
                >
                  Abrir extrato de pontos
                </Button>
              )}
            </Grid>
            <Grid item xs={12} style={{ width: '100%' }}>
              {activeCoupons === null || inactiveCoupons === null ? (
                <div align="center">
                  <CircularProgress
                    style={{
                      padding: '150px 20px',
                      boxSizing: 'content-box',
                    }}
                  />
                </div>
              ) : (
                <div>
                  <Tabs
                    value={tab}
                    onChange={handleChange}
                    style={{
                      padding: '0 12px',
                      backgroundColor: '#fff',
                    }}
                  >
                    <Tab
                      onClick={() =>
                        trackEventMatomo('Carteira', 'click', 'botao', 'Cupons ativos')
                      }
                      style={{
                        textTransform: 'none',
                        fontWeight: 'bolder',
                      }}
                      label="Cupons ativos"
                    />
                    <Tab
                      onClick={() =>
                        trackEventMatomo('Carteira', 'click', 'botao', 'Cupons inativos')
                      }
                      style={{
                        textTransform: 'none',
                        fontWeight: 'bolder',
                      }}
                      label="Cupons inativos"
                    />
                  </Tabs>

                  {tab === 0 ? (
                    activeCoupons.length === 0 ? (
                      <Typography variant="h6" color="secondary" style={{ paddingTop: '5px' }}>
                        Você não possui cupons ativos.
                      </Typography>
                    ) : (
                      <BalanceGridActive coupons={activeCoupons} />
                    )
                  ) : inactiveCoupons.length === 0 ? (
                    <Typography variant="h6" color="secondary" style={{ paddingTop: '5px' }}>
                      Você não possui cupons inativos.
                    </Typography>
                  ) : (
                    <BalanceGridInactive coupons={inactiveCoupons} />
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
