export default function reducer(state = {
  cellPhone: null,
  transactionId: null

}, action) {

  switch (action.type) {

    case 'PHONE_INPUT_PENDING':
      {
        return {
          ...state,
          cellPhone: null,
          transactionId: null
        };
      }

    case 'PHONE_INPUT_FULFILLED':
      {
        return {
          ...state,
          cellPhone: action.payload.cellPhone,
          transactionId: action.payload.transactionId
        };
      }

    case 'PHONE_INPUT_REJECTED':
      {
        return {
          ...state,
          error: action.payload
        };
      }

    default:
      return state;
  }
}