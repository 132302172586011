import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import { CircularProgress } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

import './stylesDataConfirmation.css';
import { DeleteUserPartner } from 'js/library/utils/API/apiDeleteUserPartner';
import { colorText } from 'js/library/utils/helpers';
import { theme } from 'index';
import { logout } from 'js/library/services/AuthenticationManager';

export function ModalConfirmationDeletedData({ setStatusData, userUid, toggleModal, open }) {
  const { t } = useTranslation();
  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  const [loading, setLoading] = useState(false);

  async function handleDeleteUser() {
    setLoading(true);

    try {
      await DeleteUserPartner(userUid, infoSeuClube.partnerId);

      setStatusData();
      toggleModal();
      setLoading(false);
      logout();
      toast.success(t('general.data_removed'));
      setTimeout(() => {
        window.location.replace(window.location.origin);
      }, 5000);
    } catch (_) {
      setLoading(false);
      toast.error(t('errors.try_again'));
    }
  }

  return (
    <Dialog
      sx={{
        paper: {
          overflowY: 'inherit',
        },
      }}
      open={open}
      keepMounted
      onClose={loading ? null : toggleModal}
    >
      <DialogContent className="content-modal-delete-data">
        <header className="header-modal-delete-data">
          <strong> {t('general.confirming_data_deletion')}</strong>
          <CloseIcon
            style={{ color: infoSeuClube.colorPrimary }}
            onClick={() => {
              if (!loading) toggleModal();
            }}
          />
        </header>

        <main className="main-modal-delete-data">
          <p>{t('general.confirming_data_deletion_warning')}</p>
        </main>

        <div className="area-button-modal-delete-data">
          <button
            className="button-modal-delete-data"
            disabled={loading}
            onClick={toggleModal}
            type="button"
            style={{ background: infoSeuClube.colorPrimary }}
          >
            {t('general.cancel')}
          </button>
          <button
            disabled={loading}
            className="button-modal-delete-data"
            onClick={() => handleDeleteUser()}
            type="button"
            style={{ background: infoSeuClube.colorPrimary }}
          >
            {loading ? (
              <CircularProgress
                style={{ color: colorText(theme.palette.primary.main) }}
                size={20}
              />
            ) : (
              t('general.confirm')
            )}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
