import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;

  @media (max-width: 700px) {
    height: 100%;
    padding: 1rem 0;
  }
`;

export const AsideContainer = styled.aside`
  width: 100%;
  background: ${({ $bgColor }) => ($bgColor ? $bgColor : '#6d3196')};
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1rem;
  border-radius: 10px;

  img {
    max-width: 320px;
    max-height: 320px;
    height: 100%;
    width: 100%;
  }
  strong {
    font: 700 36px sans-serif;
    line-height: 42px;
    margin-top: 16px;
  }

  p {
    font-size: 20px;
    line-height: 32px;
    margin-top: 16px;
    color: #f8f8f8;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

export const FormContainer = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media (max-width: 700px) {
    max-width: 100%;
  }
`;

export const FormContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 100%;

  strong {
    font: 700 28px sans-serif;
    line-height: 42px;
    margin-top: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
  }

  p {
    font-size: 18px;
    line-height: 32px;
    margin-top: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
  }

  @media (min-width: 700px) {
    display: none;
  }
`;

export const ClubeLogo = styled.div`
  background: ${({ $bgColor }) => $bgColor};
  border-radius: 10px;
  padding: 10px;
  max-width: 250px;
  max-height: 250px;

  img {
    align-self: center;
    width: 100%;
  }

  @media (max-width: 700px) {
    max-width: 300px;
    max-height: 300px;

    img {
      width: 100%;
    }
  }
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const FormFieldsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  max-height: calc(100vh - 220px);
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 20px;
    border: 3px solid #f2f2f2;
  }
`;

export const Field = styled.div`
  width: 100%;
`;

export const Button = styled.button`
  height: 42px;
  width: 100%;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: ${({ $bgColor }) => ($bgColor ? $bgColor : 'rgb(6, 186, 208)')};
  color: #fff;
  font-weight: 600;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const BoxCellPhone = styled.div`
  width: 100%;
  border: 1px solid #afafaf;
  border-radius: 10px;
  height: 42px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-left: 0.5rem;

  select {
    font-size: 14px;
    height: 100%;
    border-radius: 10px;
    outline: none;
  }
`;

export const Input = styled(InputMask)`
  flex: 1;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  color: #383838d6;
  border-radius: 10px;
`;

export const Adornment = styled.div`
  color: #383838;
`;

export const ContentAgroupInputAddres = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ContentAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h3 {
    margin: 0.4rem 0;
  }
`;
