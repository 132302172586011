import React from 'react';
import { addressFieldsOrder } from '../../library/utils/countryFormatter';

export function AddressCard({ data }) {
  const order = addressFieldsOrder.find(country => country.name === data.country).formatting.show;

  return(
    <div 
      style={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        width: 380, 
        padding: 20,
        borderRadius: 8,
        marginTop: 30,
        border: '1px solid #000'
      }}
    >
      <div  
        style={{
          borderBottom: '2px solid #000',
          paddingBottom: 4
        }}
      >
        <span style={{ fontSize: 18, fontWeight: 600 }}>
          { data.type }
        </span>
      </div>
      <div
        style={{
          padding: 10
        }}
      >
        <span>{ data.country }</span>
        {order && order.map((line, index) => {
          if(line.length === 1) {
            return (
              <span key={index}>{ data[line[0]] }</span>
            );
          }

          return (
            <div
              key={index} 
              style={{
                display: 'flex',
                width: '100%'
              }}
            >
              {line.map((inputName, index) => (
                <span key={index}>
                  { data[inputName] }
                  {
                    (
                      line.indexOf(inputName) !== line.length - 1 
                      &&
                      data[line[line.length - 1]]
                    ) && ', '
                  }
                </span>
              ))}
            </div>
          );
        })
        }
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-evenly'
        }}
      >
        <button
          style={{
            background: '#704296',
            border: '1px solid #000',
            borderRadius: 5,
            padding: 10,
            color: '#FFF',
            fontWeight: 'bold'
          }}
        >
          Editar
        </button>
        <button
          style={{
            background: '#704296',
            border: '1px solid #000',
            borderRadius: 5,
            padding: 10,
            color: '#FFF',
            fontWeight: 'bold'
          }}
        >
          Deletar
        </button>
      </div>
    </div>
  );
}