import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from './authToken';
import { loginUser } from 'js/library/services/AuthenticationManager.js';

export default async function createAnonymous(partnerId) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/anonymous/create',
          {
            partnerId: partnerId,
          },
          {
            headers: {
              authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          }
        )
        .then(async (result) => {
          // console.log(result);

          if (result.data.errorMessage) {
            throw result.data.errorMessage;
          } else {
            const userInfoAnonymous = {
              uId: result.data.uId,
              triiboId: result.data.triiboId,
              isAnonymous: true,
            };

            localStorage.setItem('userInfoAuxiliar', JSON.stringify(userInfoAnonymous));
            await loginUser(result.data.triiboId.replaceAll(',', '.'), result.data.passPhrase);

            resolve(result.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

