import React, { useEffect, useState } from 'react';
import { addressFieldsOrder } from 'js/library/utils/countryFormatter';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AddressCard } from './AddressCard';
import { Button } from '@mui/material';
import { trackEventMatomo, trackEventMatomoVisit } from 'js/library/utils/helpers';

export function AddressForm() {
  const defaultCountry = addressFieldsOrder.find((country) => country.code === 'BR');
  const countries = addressFieldsOrder;

  const [country, setCountry] = useState(defaultCountry);
  const [province, setProvince] = useState(defaultCountry.zones[0] || '');
  const [addressData, setAddressData] = useState({
    type: '',
    country: '',
    address1: '',
    number: '',
    neighborhood: '',
    address2: '',
    city: '',
    province: '',
    zip: '',
  });

  const [data, setData] = useState();

  function selectCountry(countryData) {
    countryData?.zones.length >= 1 ? setProvince(countryData.zones[0]) : setProvince('');
    setCountry(countryData);
  }

  function selectProvince(provinceData) {
    setProvince(provinceData);
  }

  const handleChange = (e) => {
    setAddressData((oldState) => ({
      ...oldState,
      [e.target.name]: e.target.value,
    }));
  };

  function handleSubmitAddress() {
    setData({
      ...addressData,
      country: country.name,
      province: province.code,
    });

    trackEventMatomo('endereco', 'tocar', 'button', 'Enviar');
  }

  useEffect(() => {
    trackEventMatomoVisit('endereco');
  }, []);

  const addressForm = {
    address1: (
      <TextField
        variant="outlined"
        size="small"
        name="address1"
        style={{ width: '100%' }}
        placeholder="address1"
        onChange={handleChange}
        value={addressData.address1}
      />
    ),
    number: (
      <TextField
        variant="outlined"
        size="small"
        name="number"
        style={{ width: '100%' }}
        placeholder="number"
        onChange={handleChange}
        value={addressData.number}
      />
    ),
    neighborhood: (
      <TextField
        variant="outlined"
        size="small"
        style={{ width: '100%' }}
        name="neighborhood"
        placeholder="neighborhood"
        onChange={handleChange}
        value={addressData.neighborhood}
      />
    ),
    address2: (
      <TextField
        variant="outlined"
        size="small"
        style={{ width: '100%' }}
        name="address2"
        placeholder="address2"
        onChange={handleChange}
        value={addressData.address2}
      />
    ),
    province: (
      <Autocomplete
        style={{ width: '100%', marginBottom: 10 }}
        options={country.zones}
        autoHighlight
        value={province}
        defaultValue={country.zones[0]}
        getOptionLabel={(province) => province.name}
        renderOption={(province) => (
          <span onClick={() => selectProvince(province)}>
            <span style={{ marginLeft: 5 }}>{province.name}</span>
          </span>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="province"
            variant="outlined"
            size="small"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    ),
    city: (
      <TextField
        variant="outlined"
        size="small"
        name="city"
        style={{ width: '100%' }}
        placeholder="city"
        onChange={handleChange}
        value={addressData.city}
      />
    ),
    zip: (
      <TextField
        variant="outlined"
        size="small"
        name="zip"
        style={{ width: '100%' }}
        placeholder="zip"
        onChange={handleChange}
        value={addressData.zip}
      />
    ),
  };

  return (
    <div
      className="paper-container"
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        padding: 20,
      }}
    >
      <Autocomplete
        style={{ width: '100%', marginBottom: 10 }}
        options={countries}
        autoHighlight
        value={country}
        defaultValue={countries[countries.findIndex((country) => country.code === 'BR')]}
        getOptionLabel={(country) => country.name}
        renderOption={(country) => (
          <span onClick={() => selectCountry(country)} style={{ width: '100%', height: '100%' }}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
              alt={`${country.name} flag`}
            />
            <span style={{ marginLeft: 5 }}>{country.name}</span>
          </span>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="País"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <TextField
        variant="outlined"
        placeholder="type"
        name="type"
        onChange={handleChange}
        style={{ width: '100%' }}
        size="small"
      />
      {country &&
        country.formatting.edit.map((line, index) => {
          if (line.length === 1) {
            return (
              <div key={index} style={{ marginTop: 20 }}>
                {addressForm[line[0]]}
              </div>
            );
          }
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 20,
              }}
            >
              {line.map((inputName, index) => (
                <div
                  key={index}
                  style={{
                    width: line.length > 2 ? '32%' : '49%',
                    justifyContent: 'flex-end',
                  }}
                >
                  {addressForm[inputName]}
                </div>
              ))}
            </div>
          );
        })}
      <Button onClick={handleSubmitAddress} variant="contained">
        Enviar
      </Button>
      {data && <AddressCard data={data} />}
    </div>
  );
}
