import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export default function getAllMessagesFromUser(uId, queryType, filter) {
  return new Promise((resolve, reject) => {
    if (uId) {
      authToken(uId)
        .then((token) => {
          axios
            .get(`${cfac22('API_HOST_V2')}/message?origin=${queryType}`, {
              headers: {
                authorization: `Bearer ${token}`,
                contentType: 'application/json',
              },
            })
            .then((response) => {
              let filteredList = [];
              if (filter) {
                let twoMonthAgo = new Date();
                twoMonthAgo.setMonth(twoMonthAgo.getMonth() - 3);
                twoMonthAgo.setHours(0, 0, 0, 0);

                filteredList = response.data.result.filter((item) => item.data > twoMonthAgo);
              }
              resolve(filter ? filteredList : response.data.result);
            })
            .catch((error) => {
              reject(error.response.data);
            });
        })
        .catch((error) => {
          console.log('ERROR_TOKEN:', error);
          reject(error);
        });
    } else {
      reject(null);
    }
  });
}

