import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export const answerNpsForm = async (uId, data) => {
  const token = await authToken(uId);

  const url = `${cfac22('API_HOST_V2')}/nps/answerNpsForm`;
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const body = data;

  try {
    const response = await axios.post(url, body, headers);
    if (response?.data?.success) {
      return response?.data;
    }
  } catch (err) {
    const error = err?.response?.data?.error;
    return error;
  }
};
