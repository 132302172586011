import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Typography, Grid, Divider, CircularProgress } from '@mui/material';
import { colorText, trackEventMatomoElementId } from 'js/library/utils/helpers';
import { theme } from 'index';
import cfac22 from 'js/library/utils/cfac22/cfac22';

export default function CuponsComponent({ promotionList }) {
  const { t } = useTranslation();

  return (
    <div>
      {promotionList === null ? (
        <div align="center">
          <CircularProgress
            style={{
              padding: '150px 20px',
              boxSizing: 'content-box',
              color: colorText(theme.palette.primary.main),
            }}
          />
        </div>
      ) : (
        <div>
          {promotionList.length > 0 ? (
            <div>
              {promotionList.map((row, i) => {
                const id = row.id;
                row = row[row.type];

                return (
                  <div key={id}>
                    <Grid container spacing={3} style={{ width: '100%' }}>
                      <Grid item md={5} lg={5}>
                        <Link
                          onClick={() =>
                            trackEventMatomoElementId(
                              'Estabelecimento',
                              'tocar',
                              'listaDePromocoes',
                              id
                            )
                          }
                          to={{
                            pathname: '/oferta/',
                            search: '?id=' + id,
                            state: row,
                          }}
                          style={{
                            textDecoration: 'none',
                          }}
                        >
                          <img
                            alt="promo"
                            className="img-radius"
                            width="100%"
                            src={
                              cfac22('STORAGE_URL') + 'promocao%2F' + row.thumbnail + '?alt=media'
                            }
                          />
                        </Link>
                      </Grid>
                      <Grid item md={7} lg={7}>
                        <Link
                          onClick={() =>
                            trackEventMatomoElementId(
                              'Estabelecimento',
                              'tocar',
                              'listaDePromocoes',
                              id
                            )
                          }
                          to={{
                            pathname: '/oferta/',
                            search: '?id=' + id,
                            state: row,
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          {/* <Typography color="primary" variant="h6">
															{row.establishmentName}
														</Typography> */}
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                            style={{
                              color: 'black',
                              fontWeight: 'bolder',
                              fontSize: '1rem',
                              padding: '0.8rem',
                            }}
                          >
                            <i>{row.title}</i>
                          </Typography>
                          <Typography
                            className="limit-text"
                            variant="caption"
                            style={{
                              fontSize: '0.8rem',
                              padding: '0.8rem',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            {row.description}
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                    {i < Object.keys(promotionList).length - 1 ? (
                      <Divider style={{ margin: '20px 0px', float: 'right' }} width="80%" />
                    ) : null}
                  </div>
                );
              })}
            </div>
          ) : (
            <Typography
              style={{
                textAlign: 'center',
                fontSize: '20px',
                color: '#8B8B8B',
              }}
              className="limit-text"
              variant="caption"
            >
              {t('general.establishment_without_coupons')}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}
