import {combineReducers} from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//Models
import phoneInputModel from 'js/core/reducers/models/phoneInputModel.js';
import codeInputModel from 'js/core/reducers/models/codeInputModel.js';
import userFormModel from 'js/core/reducers/models/userFormModel.js';
import editUserInfoModel from 'js/core/reducers/models/editUserInfoModel.js';

//Components
import phoneInputComponent from 'js/core/reducers/components/phoneInputReducer.js';
import codeInputComponent from 'js/core/reducers/components/codeInputReducer.js';
import userFormComponent from 'js/core/reducers/components/userFormReducer.js';
import editUserInfoComponent from 'js/core/reducers/components/editUserInfoReducer.js';

export default combineReducers({
    phoneInputModel: createReducer('phoneInput', phoneInputModel ),
    codeInputModel: createReducer('codeInput', codeInputModel ),
    userFormModel: createReducer('userForm', userFormModel),
    editUserInfoModel: createReducer('editUserInfo', editUserInfoModel ),

    phoneInputComponent: phoneInputComponent,
    codeInputComponent: codeInputComponent,
    userFormComponent: userFormComponent,
    editUserInfoComponent: editUserInfoComponent
});

function createReducer(key, reducer) {
    return persistReducer(createConfig(key), reducer);
}

function createConfig(key) {
    return {
      key: key,
      storage
    };
}