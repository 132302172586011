import { colorText } from 'js/library/utils/helpers';
import React from 'react';

export const HomeIcon = ({ color }) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 13.75V28.5C5 28.7652 5.10536 29.0196 5.29289 29.2071C5.48043 29.3946 5.73478 29.5 6 29.5H12V21C12 20.6022 12.158 20.2206 12.4393 19.9393C12.7206 19.658 13.1022 19.5 13.5 19.5H18.5C18.8978 19.5 19.2794 19.658 19.5607 19.9393C19.842 20.2206 20 20.6022 20 21V29.5H26C26.2652 29.5 26.5196 29.3946 26.7071 29.2071C26.8946 29.0196 27 28.7652 27 28.5V13.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 16.4999L16.6806 3.74988C16.3681 3.41988 15.6375 3.41613 15.3194 3.74988L2 16.4999"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const WalletIcon = ({ color }) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26 9.5H6C4.34315 9.5 3 10.8431 3 12.5V24.5C3 26.1569 4.34315 27.5 6 27.5H26C27.6569 27.5 29 26.1569 29 24.5V12.5C29 10.8431 27.6569 9.5 26 9.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M25.71 9.49997V7.62497C25.7099 7.1651 25.6082 6.71093 25.4123 6.29486C25.2164 5.8788 24.9311 5.51109 24.5768 5.21799C24.2224 4.92488 23.8077 4.71359 23.3623 4.5992C22.9169 4.48481 22.4517 4.47013 22 4.55622L5.54 7.3656C4.8252 7.50181 4.18035 7.88321 3.71665 8.444C3.25296 9.00478 2.99951 9.70981 3 10.4375V13.5"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M23 20.5C22.6044 20.5 22.2178 20.3827 21.8889 20.1629C21.56 19.9432 21.3036 19.6308 21.1522 19.2654C21.0009 18.8999 20.9613 18.4978 21.0384 18.1098C21.1156 17.7219 21.3061 17.3655 21.5858 17.0858C21.8655 16.8061 22.2219 16.6156 22.6098 16.5384C22.9978 16.4613 23.3999 16.5009 23.7654 16.6522C24.1308 16.8036 24.4432 17.06 24.6629 17.3889C24.8827 17.7178 25 18.1044 25 18.5C25 19.0304 24.7893 19.5391 24.4142 19.9142C24.0391 20.2893 23.5304 20.5 23 20.5Z"
        fill={color}
      />
    </svg>
  );
};

export const MessageIcon = ({ color }) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.6364 4.68188H5.36364C4.73676 4.68188 4.13556 4.93091 3.69229 5.37418C3.24903 5.81745 3 6.41865 3 7.04552V21.2273C3 21.8542 3.24903 22.4554 3.69229 22.8987C4.13556 23.342 4.73676 23.591 5.36364 23.591H8.90909V28.3182L13.6364 23.591H26.6364C27.2632 23.591 27.8644 23.342 28.3077 22.8987C28.751 22.4554 29 21.8542 29 21.2273V7.04552C29 6.41865 28.751 5.81745 28.3077 5.37418C27.8644 4.93091 27.2632 4.68188 26.6364 4.68188Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.72754 11.7727H24.273"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 16.5H18"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="21" cy="16.5" r="1" fill={color} />
      <circle cx="24" cy="16.5" r="1" fill={color} />
    </svg>
  );
};

export const BurgerIcon = ({ color }) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 22.5H26"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 16.5H26"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10.5H26"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ProfileIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.75 4.5C10.6275 6.15219 9.37503 7.5 8.00003 7.5C6.62503 7.5 5.37034 6.1525 5.25003 4.5C5.12503 2.78125 6.34378 1.5 8.00003 1.5C9.65628 1.5 10.875 2.8125 10.75 4.5Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00008 9.5C5.28133 9.5 2.52195 11 2.01133 13.8313C1.94976 14.1725 2.14289 14.5 2.50008 14.5H13.5001C13.8576 14.5 14.0507 14.1725 13.9891 13.8313C13.4782 11 10.7188 9.5 8.00008 9.5Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export const TermsIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 6.91406V13C13 13.3978 12.842 13.7794 12.5607 14.0607C12.2794 14.342 11.8978 14.5 11.5 14.5H4.5C4.10218 14.5 3.72064 14.342 3.43934 14.0607C3.15804 13.7794 3 13.3978 3 13V3C3 2.60218 3.15804 2.22064 3.43934 1.93934C3.72064 1.65804 4.10218 1.5 4.5 1.5H7.58594C7.85106 1.50004 8.10532 1.60536 8.29281 1.79281L12.7072 6.20719C12.8946 6.39468 13 6.64894 13 6.91406Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M8 1.75V5.5C8 5.76522 8.10536 6.01957 8.29289 6.20711C8.48043 6.39464 8.73478 6.5 9 6.5H12.75"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 9H10.5"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 11.5H10.5"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SacIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3337 7.33333C13.3337 4.38781 10.9459 2 8.00033 2C5.05481 2 2.66699 4.38781 2.66699 7.33333"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33301 10.2921V9.04093C1.33301 8.42906 1.7494 7.89579 2.34296 7.74739L3.50266 7.45746C3.75512 7.39433 3.99967 7.58526 3.99967 7.84553V11.4875C3.99967 11.7478 3.75512 11.9387 3.50266 11.8756L2.34296 11.5857C1.7494 11.4373 1.33301 10.904 1.33301 10.2921Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
      />
      <path
        d="M14.6667 10.2921V9.04093C14.6667 8.42906 14.2503 7.89579 13.6567 7.74739L12.497 7.45746C12.2445 7.39433 12 7.58526 12 7.84553V11.4875C12 11.7478 12.2445 11.9387 12.497 11.8756L13.6567 11.5857C14.2503 11.4373 14.6667 10.904 14.6667 10.2921Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
      />
      <path
        d="M13.3337 12V12.3333C13.3337 13.0697 12.7367 13.6667 12.0003 13.6667H9.66699"
        stroke="#1E1E1E"
        strokeWidth="1.5"
      />
      <path
        d="M9 14.6665H7C6.44771 14.6665 6 14.2188 6 13.6665C6 13.1142 6.44771 12.6665 7 12.6665H9C9.55227 12.6665 10 13.1142 10 13.6665C10 14.2188 9.55227 14.6665 9 14.6665Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const LoginIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 5.5V4.25C5.5 3.91848 5.6317 3.60054 5.86612 3.36612C6.10054 3.1317 6.41848 3 6.75 3H13.25C13.5815 3 13.8995 3.1317 14.1339 3.36612C14.3683 3.60054 14.5 3.91848 14.5 4.25V11.75C14.5 12.0815 14.3683 12.3995 14.1339 12.6339C13.8995 12.8683 13.5815 13 13.25 13H6.75C6.41848 13 6.10054 12.8683 5.86612 12.6339C5.6317 12.3995 5.5 12.0815 5.5 11.75V10.5"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 10.5L11 8L8.5 5.5"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 8H10.5"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LogoutIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42 22V17C42 15.6739 41.4732 14.4021 40.5355 13.4645C39.5979 12.5268 38.3261 12 37 12H11C9.67392 12 8.40215 12.5268 7.46447 13.4645C6.52678 14.4021 6 15.6739 6 17V47C6 48.3261 6.52678 49.5979 7.46447 50.5355C8.40215 51.4732 9.67392 52 11 52H37C38.3261 52 39.5979 51.4732 40.5355 50.5355C41.4732 49.5979 42 48.3261 42 47V42"
        stroke="#1A1A1A"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48 42L58 32L48 22"
        stroke="#1A1A1A"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 32H56"
        stroke="#1A1A1A"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EmailIcon = ({ color }) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_116_153)">
        <path
          d="M32 0C14.326 0 0 14.326 0 32C0 49.672 14.326 64 32 64C49.674 64 64 49.672 64 32C64 14.326 49.674 0 32 0Z"
          fill={color}
        />
        <path d="M13.0361 43.63L23.4141 30.582L13.0361 24.238V43.63Z" fill={colorText(color)} />
        <path
          d="M39.0002 31.4919L31.9782 35.8159L24.9442 31.5159L14.2202 44.9999H49.7342L39.0002 31.4919Z"
          fill={colorText(color)}
        />
        <path
          d="M31.9761 33.728L50.9641 22.034V19H13.0361V22.152L31.9761 33.728Z"
          fill={colorText(color)}
        />
        <path d="M40.5264 30.552L50.9644 43.686V24.124L40.5264 30.552Z" fill={colorText(color)} />
      </g>
      <defs>
        <clipPath id="clip0_116_153">
          <rect width="64" height="64" fill={colorText(color)} />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CellphoneIcon = ({ color }) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_121_231)">
        <path
          d="M32 1.93945C25.8628 1.93945 19.8634 3.75935 14.7605 7.169C9.65762 10.5787 5.68039 15.4249 3.33178 21.095C0.983171 26.765 0.368668 33.0042 1.56598 39.0235C2.76329 45.0428 5.71864 50.5718 10.0583 54.9115C14.398 59.2512 19.927 62.2065 25.9463 63.4038C31.9656 64.6011 38.2048 63.9866 43.8748 61.638C49.5449 59.2894 54.3911 55.3122 57.8008 50.2093C61.2105 45.1064 63.0303 39.107 63.0303 32.9698C63.0303 24.74 59.7611 16.8473 53.9418 11.028C48.1225 5.20871 40.2298 1.93945 32 1.93945Z"
          fill={color}
        />
        <path
          d="M40.1649 15.5151H24.2425C22.3252 15.5151 20.771 17.0694 20.771 18.9867V46.9527C20.771 48.87 22.3252 50.4242 24.2425 50.4242H40.1649C42.0822 50.4242 43.6364 48.87 43.6364 46.9527V18.9867C43.6364 17.0694 42.0822 15.5151 40.1649 15.5151Z"
          fill={colorText(color)}
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path d="M20.771 40.9019H43.6364" stroke={color} strokeMiterlimit="10" />
        <path d="M20.771 21.8667H43.6364" stroke={color} strokeMiterlimit="10" />
        <path
          d="M32.2038 47.2534C33.1035 47.2534 33.8329 46.5414 33.8329 45.6631C33.8329 44.7848 33.1035 44.0728 32.2038 44.0728C31.3041 44.0728 30.5747 44.7848 30.5747 45.6631C30.5747 46.5414 31.3041 47.2534 32.2038 47.2534Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_121_231">
          <rect width="64" height="64" fill={colorText(color)} />
        </clipPath>
      </defs>
    </svg>
  );
};
