import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';

import './styleEstablishmentLinks.css';

function ErrorMessageEstablishmentLinks() {
  const { t } = useTranslation();

  return (
    <div className="estab-loading-info">
      <ErrorOutlineIcon style={{ color: '#ef4022', fontSize: '5rem' }} />
      <h1>{t('errors.try_again')}</h1>
    </div>
  );
}

export default ErrorMessageEstablishmentLinks;

