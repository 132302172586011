import Countries from './countries.json';

function orderFormatter(order) {
  const formOrderFormatted = order
    .replace(/{country}_/g, '').replace(/{country}/g, '') // Remove all country occurrences
    .replace(/{company}_/g, '').replace(/{company}/g, '') // Remove all company occurrences
    .replace(/{firstName}_/g, '').replace(/{firstName}/g, '') // Remove all firstName occurrences
    .replace(/{lastName}_/g, '').replace(/{lastName}/g, '') // Remove all lastName occurrences
    .replace(/{phone}_/g, '').replace(/{phone}/g, '') // Remove all phone occurrences
    .split('_');
  formOrderFormatted.pop();
  const removeSpaces = formOrderFormatted.map(field => field.replace(/\s/g, ''));
  const underscore = removeSpaces.map(field => field.replace(/}{/g, '_'));
  const cleanup = underscore.map(field => field.replace(/[{}]/g, ''));
  
  const formOrder = cleanup.map(item => item.split('_'));

  return formOrder;
}

export const addressFieldsOrder = Countries.map(item => {
  const editOrder = orderFormatter(item.formatting.edit);
  const showOrder = orderFormatter(item.formatting.show);

  return {
    name: item.name,
    code: item.code,
    formatting: {
      edit: editOrder,
      show: showOrder
    },
    labels: item.labels,
    zones: item.zones
  };

});