import React from "react";

export function WhatsappButton(cellphone) {
  let windowWidth = window.innerWidth;

  return (
    <>
      <a
        href={`https://api.whatsapp.com/send?phone=${cellphone.cellphone}&text=Ol%C3%A1,%20tudo%20bem%20?`}
        className="whatsapp-button"
        target="_blank"
        rel="noreferrer"
        style={{
          position: "fixed",
          right: "15px",
          bottom: `${windowWidth <= 960 ? "65px" : "15px"}`,
          zIndex: "99999999",
        }}
      >
        <img
          style={{
            width: `${windowWidth <= 960 ? "40px" : "60px"}`,
            height: `${windowWidth <= 960 ? "40px" : "60px"}`,
          }}
          src="https://i.ibb.co/VgSspjY/whatsapp-button.png"
          alt="botão whatsapp"
        />
      </a>
    </>
  );
}
