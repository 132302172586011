import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import pt_BR from './languages/pt-BR.json';
import es from './languages/es.json';
import en from './languages/en.json';
import fr from './languages/fr.json';
import de from './languages/de.json';
import it from './languages/it.json';
import zh from './languages/zh.json';

const languageNavigator = navigator.language.replace('-', '_');

const resources = {
  //espanhol
  es: {
    translation: es,
  },
  //portugues
  pt_BR: {
    translation: pt_BR,
  },
  //ingles
  en: {
    translation: en,
  },
  //frances
  fr: {
    translation: fr,
  },
  //alemao
  de: {
    translation: de,
  },
  //italiano
  it: {
    translation: it,
  },
  //chines
  zh: {
    translation: zh,
  },
};

function detectLanguage() {
  switch (languageNavigator) {
    case 'es':
      return 'es';
    case 'en':
      return 'en';
    case 'fr':
      return 'fr';
    case 'it':
      return 'it';
    case 'de':
      return 'de';
    case 'zh':
      return 'zh';
    default:
      return 'pt_BR';
  }
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    compatibilityJSON: 'v3',
    resources,
    detection: {
      order: ['navigator', 'localStorage'],
    },
    fallbackLng: detectLanguage(),

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
