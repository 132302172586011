import styled from 'styled-components';

export const Label = styled.label`
  cursor: pointer;
`;

export const InputCheckbox = styled.input.attrs((props) => ({
  type: 'checkbox',
}))`
  margin-right: 0.5rem;
`;
