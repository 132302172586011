import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from 'js/library/utils/cfac22/cfac22';

export default function ApplydiscountCoupon(uId, leadId, discountCoupon) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .post(
            // eslint-disable-next-line no-undef
            `${cfac22('API_HOST_V2')}/checkout/coupon/apply`,
            {
              leadId,
              discountCoupon,
            },
            {
              headers: { authorization: `Bearer ${token}` },
            }
          )
          .then(async (result) => {
            // console.log("RESULT_CreateLead: ", result);
            resolve(result.data);
          })
          .catch((error) => {
            //console.log("ERROR_CreateLead: ", error);
            reject(error.response.data);
          });
      })
      .catch((error) => {
        //console.log("ERROR_TOKEN:", error);
        reject(error);
      });
  });
}
