import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogTitle, IconButton } from '@mui/material';

export function ModalVoucher({ voucherId, isOpen, setIsOpen }) {
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={() => setIsOpen(false)} style={{ cursor: 'pointer' }}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogTitle>
        Solicitação de resgatado realizada com sucesso! O Voucher {voucherId} foi consumido de sua
        carteira para efetivar o resgate. Consulte sua carteira para mais informações.
      </DialogTitle>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '16px',
          padding: '1rem 0',
        }}
      >
        <Button
          onClick={() => setIsOpen(false)}
          variant="contained"
          color="primary"
          style={{ fontWeight: '700' }}
        >
          Fechar
        </Button>
      </div>
    </Dialog>
  );
}
