import React, { useContext, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import deleteImg from '../../../styles/assets/delete-user-data.svg';
import updateImg from '../../../styles/assets/update-user-data.svg';

import './stylesDataConfirmation.css';
import * as S from './styles';
import { SignUpContext } from 'js/context/SignUpContext';
import { getUserPartner } from 'js/library/utils/API/apiGetUserPartner';
import { loginUser } from 'js/library/services/AuthenticationManager';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function StatusMessage({ isUpdateData, userCellphone, userUid, typeAction }) {
  const { setCheckUser } = useContext(SignUpContext);
  const { t } = useTranslation();
  const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSignIn = () => {
    setLoading(true);
    getUserPartner(seuClubePersist.partnerId, userUid)
      .then(async (res) => {
        //logando usuário no firebase
        localStorage.removeItem('userInfoAuxiliar');
        await loginUser(res.triiboId.replace(/[,]/gi, '.'), res.passPhrase);

        dispatch({
          type: 'CODE_INPUT_FULFILLED',
          payload: {
            cellPhone: userCellphone,
            userInfo: {
              ...res,
              triiboId: res.triiboId,
              uId: userUid,
            },
          },
        });

        setLoading(false);
        navigate('/');
        setCheckUser(true);
      })
      .catch((error) => {
        dispatch({
          type: 'CODE_INPUT_REJECTED',
          payload: error,
        });
        setLoading(false);
      });
  };

  return (
    <div className="data-confirmation__container-status-message">
      <img src={isUpdateData ? updateImg : deleteImg} alt="Message de fim" />
      <div className="data-confirmation__status-message">
        <Typography
          variant="h3"
          style={{ fontWeight: 'bold', color: seuClubePersist.colorPrimary }}
        >
          {t('general.all_right')}
        </Typography>
        <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
          {isUpdateData
            ? t('general.your_data_was_updated_successfully')
            : t('general.your_data_was_completely_removed')}
        </Typography>
        {typeAction === 'atualizar' && (
          <S.Button onClick={handleSignIn} $bgColor={seuClubePersist.colorPrimary}>
            {loading ? (
              <CircularProgress sx={{ color: '#fff' }} size={30} thickness={5} />
            ) : (
              t('general.go_to_site')
            )}
          </S.Button>
        )}
      </div>
    </div>
  );
}

export default StatusMessage;
