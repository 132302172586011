import styled from 'styled-components';

const seuClubeColors = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
const main = seuClubeColors && seuClubeColors.colorPrimary;
// const secondary = seuClubeColors && seuClubeColors.colorSecondary;

export const Container = styled.div`
  .place-header {
    width: "100%";
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    margin-bottom: 10px;
    padding: 5px;
  }

  .place-header > span {
    color: #9b9a9b;
    font-size: 1.3rem;
    font-weight: bold;
    color: ${main};
  }

  .iconHeart {
    /* color: #6E3296; */
    color: #9b9a9b;
    cursor: pointer;
    font-size: 1.9rem;
  }

  .iconHeart:hover {
    color: #c4c2c4;
  }

  .tab-navigation {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 20px;
    margin-bottom: 15px;
  }

  .tab-navigation ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px;
  }

  .tabLabel {
    font-family: Source Sans Pro, sans-serif;
    font-size: 1.07rem;
    color: #8b8b8b;
    padding: 10px;
    font-weight: 550;
    cursor: pointer;
  }

  .tabLabel:hover {
    color: #b4b3b3;
  }

  .activeTab {
    color: ${main};
  }

  .activeTab:hover {
    color: ${main};
  }

  .contentMaps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
  }

  .notActiveSlide {
    display: none;
  }

  @media (max-width: 500px) {
    .tab-navigation {
      padding: 0px;
    }

    .tab-navigation i {
      font-size: 1rem;
    }
  }
`;
