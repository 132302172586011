import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { authTokenWithSC } from './getSecurityCodeWithSC';

export function authCode_v1(uId, cellPhone, sendType, pathname) {
  const languageNavigator = navigator.language.replace('-', '_');

  return new Promise((resolve, reject) => {
    authTokenWithSC(uId)
      .then((token) => {
        //console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', result);
        axios
          .post(
            cfac22('API_HOST_V2') + '/authCode',
            {
              cellphone: cellPhone,
              platform: 'SeuClube',
              languageCode: languageNavigator === 'pt_BR' ? 'pt_BR' : 'es',
              sendType,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            //console.log('RESULTADO AUTH SMS ===>', result);
            if (result.data.error) {
              reject(result.data.error);
            } else {
              window.pathname = pathname;

              const objReply = {
                cellPhone,
                transactionId: !result.data.error
                  ? result.data.transactionId
                  : null,
                error: result.data.error === undefined && false,
              };

              resolve(objReply);
            }
          })
          .catch((error) => {
            //console.log('ERROR AUTH SMS ===>', error);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN C/ UID NULL ===>', error);
        reject(error);
      });
  });
}
