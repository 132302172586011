import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';

import GoBack from 'js/containers/GoBack.js';

import triiboThumb from 'styles/assets/triibo-thumb.png';
import IconStar from 'styles/assets/HelpPages/IconStar.png';
import IconCart from 'styles/assets/HelpPages/IconCart.png';
import IconEstablishment from 'styles/assets/HelpPages/IconEstablishment.png';
import IconWiFi from 'styles/assets/HelpPages/IconWiFi.png';
import IconEmail from 'styles/assets/HelpPages/IconEmail.png';
import { trackEventMatomoVisit } from 'js/library/utils/helpers';
import { Trans } from 'react-i18next';

export default function About() {
  useEffect(() => {
    document.title = 'Sobre';
    trackEventMatomoVisit('Sobre');
  }, []);

  return (
    <div className="paper-container">
      <GoBack />

      <img
        src={triiboThumb}
        alt="triibo"
        style={{ maxWidth: '100%', margin: '1em auto', display: 'block' }}
      />
      <Grid
        container
        style={{
          padding: '1em 0',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Grid item sm={4} xs={12}>
          <img
            src={IconStar}
            alt="promoções"
            style={{ maxWidth: '100%', margin: '0 auto', display: 'block' }}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography variant="h6" style={{ lineHeight: 'initial', margin: '1em 0' }}>
            <Trans i18nKey="about.have_access_to_promo_and_discounts" />
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          padding: '1em 0',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Grid item sm={4} xs={12}>
          <img
            src={IconCart}
            alt="premios"
            style={{ maxWidth: '100%', margin: '0 auto', display: 'block' }}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography variant="h6" style={{ lineHeight: 'initial', margin: '1em 0' }}>
            <Trans i18nKey="about.be_part_of_the_triibo" />
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          padding: '1em 0',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Grid item sm={4} xs={12}>
          <img
            src={IconEstablishment}
            alt="estabelecimentos"
            style={{ maxWidth: '100%', margin: '0 auto', display: 'block' }}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography variant="h6" style={{ lineHeight: 'initial', margin: '1em 0' }}>
            <Trans i18nKey="about.find_establishments_and_services" />
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          padding: '1em 0',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Grid item sm={4} xs={12}>
          <img
            src={IconWiFi}
            alt="wifi"
            style={{ maxWidth: '100%', margin: '0 auto', display: 'block' }}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography variant="h6" style={{ lineHeight: 'initial', margin: '1em 0' }}>
            <Trans i18nKey="about.thousands_of_wifi_networks" />
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          padding: '1em 0',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Grid item sm={4} xs={12}>
          <img
            src={IconEmail}
            alt="novidades"
            style={{ maxWidth: '100%', margin: '0 auto', display: 'block' }}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography variant="h6" style={{ lineHeight: 'initial', margin: '1em 0' }}>
            <Trans i18nKey="about.stay_up_to_date_with_promo_and_news" />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
