import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from 'js/library/utils/cfac22/cfac22';
export default function generatePixQrCode(uid, leadId) {
  return new Promise((resolve, reject) => {
    authToken(uid)
      .then((token) => {
        axios
          .post(
            // eslint-disable-next-line no-undef
            `${cfac22('API_HOST_V2')}/checkout/payment/qrCode`,
            {
              leadId,
            },
            {
              headers: { authorization: `Bearer ${token}` },
            }
          )
          .then(async (result) => {
            // console.log("RESULT GENERATE QRCODE: ", result);
            resolve(result.data);
          })
          .catch((error) => {
            //console.log("ERROR GENERATE QRCODE: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        //console.log("ERROR_TOKEN:", error);
        reject(error);
      });
  });
}
