import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import * as S from './styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function PaymentConfirmed() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="paper-container">
      <S.Container>
        <CheckCircleOutlineIcon
          sx={{
            fontSize: 80,
            color: '#2ce068',
          }}
        />

        <h2>{t('checkout.payment_confirmed')}.</h2>
        <Button variant="contained" onClick={() => navigate('/')}>
          {t('checkout.home')}
        </Button>
      </S.Container>
    </div>
  );
}
