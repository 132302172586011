import React from 'react';

import { Avatar, Button, Grid, Paper, Typography } from '@mui/material';
import { colorText, trackEventMatomoElementId, isFrameUrl } from '../../library/utils/helpers';
import cfac22 from 'js/library/utils/cfac22/cfac22';

const FeedsBody = (props) => {
  const { action, appFilter, id, title, image, message, sender, colorPrimary } = props;

  function formatPostUrl() {
    if (action.type === 'browser' || action.type === 'webview' || action.type === 'url') {
      if (appFilter.url && isFrameUrl(appFilter.url)) {
        const urlPost = new URL(appFilter.url);
        appFilter.url = urlPost.pathname + urlPost.search;
      }

      return appFilter.url;
    }

    if (action.type === 'establishment_info') {
      return '/estabelecimento/?id=' + id;
    }

    if (
      action.type === 'promotion_businessPartner' ||
      action.type === 'promotion_qrCode' ||
      action.type === 'promotion_selfValidation' ||
      action.type === 'promotion_takeVoucher' ||
      action.type === 'product_qrCode' ||
      action.type === 'product_selfValidation' ||
      action.type === 'promotion_item'
    ) {
      return '/oferta/?id=' + id;
    }

    if (
      action.type === 'promotion_list' ||
      action.type === 'product_list' ||
      action.type === 'establishment_list'
    ) {
      return '/ofertas/?id=' + id;
    }

    if (action.type === 'app_screen') {
      let url = '/';
      switch (id) {
        case 'screen_home':
          return url;
        case 'screen_statement':
          return url + 'carteira';
        case 'screen_wallet':
          return url + 'carteira';
        case 'screen_profile':
          return url + 'meusDados';
        case 'screen_notifications':
          return url + 'caixaDeEntrada';
        case 'screen_recommended_orgs_list':
          return url + 'addTriibos';
        case 'screen_chat':
          return url + 'oferta/?id=-MYH-BH6SKtY6R7Wkzyb';
        default:
          // console.log('Não é um card interno válido');
          return '';
      }
    }
  }

  return (
    <Paper
      id={`feed-${id}`}
      elevation={2}
      style={{
        width: '85%',
        maxWidth: 700,
        marginBottom: 20,
        display: 'flex',
        padding: 20,
      }}
    >
      <Grid container style={{ gap: 5 }}>
        <Grid container style={{ padding: '10px 0', alignItems: 'center', gap: 10 }}>
          <Grid>
            <Avatar
              alt={`avatar-${sender.id}`}
              src={cfac22('STORAGE_URL') + encodeURIComponent(sender.logo) + '?alt=media'}
            />
          </Grid>
          <Grid>
            <Typography variant="h5" style={{ textAlign: 'left' }}>
              {' '}
              {sender.name}{' '}
            </Typography>
          </Grid>
        </Grid>
        <img
          src={cfac22('STORAGE_URL') + encodeURIComponent(image) + '?alt=media'}
          alt={`feed-media`}
          style={{
            width: '100%',
            maxWidth: 700,
          }}
        />
        <Grid container>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              {title}
            </Typography>
            <Typography variant="body2">{message}</Typography>
          </Grid>
          {action ? (
            <Grid item xs={12} style={{ textAlign: 'center', marginTop: 15 }}>
              <a
                href={formatPostUrl()}
                onClick={() => trackEventMatomoElementId('novidades', 'click', 'post-button', id)}
              >
                <Button
                  variant="contained"
                  style={{
                    color: colorText(colorPrimary),
                    background: colorPrimary,
                  }}
                >
                  {action.label}
                </Button>
              </a>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FeedsBody;

