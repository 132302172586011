import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';

export function getBalance_v1(uId, triiboId) {
  return new Promise((resolve, reject) => {
    if (uId && triiboId) {
      getChannelToken_v1(uId)
        .then((result) => {
          //console.log('RESULTADO GET CHANNEL TOKEN ===>', result);
          axios
            .post(cfac22('API_HOST_V1') + 'getBalance_v1', {
              triiboHeader: {
                apiToken: cfac22('apiToken'),
                channelGroup: cfac22('channelGroup'),
                channelName: cfac22('channelName'),
                uId: uId,
                channelTokenId: result,
                channelId: cfac22('channelId'),
                sessionId: '001',
                transactionId: '002',
              },
              queryPartnerAPI: ['getBalance'],
              triiboId: triiboId,
            })
            .then((result) => {
              //console.log('RESULTADO GET BALANCE ===>', result);
              resolve(result.data.success.balance);
            })
            .catch((error) => {
              //console.log('ERROR GET BALANCE ---->', error);
              reject(error);
            });
        })
        .catch((error) => {
          //console.log('ERROR GET CHANNEL TOKEN ---->', error);
          reject(error);
        });
    } else {
      reject(null);
    }
  });
}

