import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import phone from 'phone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { SignUpContext } from 'js/context/SignUpContext';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import {
  maskCPF,
  randomizeNumbersString,
  findValueInArray,
  maskCnpj,
  colorText,
  convertDateDefault,
  convertToTimestamp,
  isValidDate,
  trackEventMatomoVisit,
  trackEventMatomo,
} from 'js/library/utils/helpers';

// STYLES
import Checkbox from '../DesignSystem/Checkbox';
import './stylesDataConfirmation.css';
import * as S from './styles';
import Input from '../DesignSystem/Input';
import imgIllustration from '../../../styles/assets/illustration.svg';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
} from '@mui/material';

// COMPONENTS
import { ModalConfirmationDeletedData } from './ModalConfirmationDeletedData';
import phoneMaskArray from 'js/components/LoginForms/phoneMaskArray.json';
import { ModalValidationCode } from './ModalValidationCode';
import LoadingInfo from './LoadingInfo';
import ErrorMessage from './ErrorMessage';
import StatusMessage from './StatusMessage';

// APIS
import { authCode_v1 } from 'js/library/utils/API/authCode_v1';
import { getUserPartner } from 'js/library/utils/API/apiGetUserPartner';
import { editUserPartner } from 'js/library/utils/API/apiEditUserPartner';
const UFs = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

function DataConfirmation() {
  const { t } = useTranslation();
  const { formFields, infosSeuClube } = useContext(SignUpContext);
  const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
  const partnerId = `${seuClubePersist.id}_${seuClubePersist.clubeId}`;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uId = urlParams.get('uId');

  const DESCRIPTION = t('general.you_are_registered_by', {
    establishmentCreatorName: seuClubePersist.name || 'um estabelecimento parceiro Triibo',
  });
  const TITLE = 'Confirme seus dados.';

  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading02, setLoading02] = useState(false);
  const [loading03, setLoading03] = useState(false);
  const messageErrorApi = '';
  const [typeAction, setTypeAction] = useState('');
  const [userCellphone, setUserCellphone] = useState('');
  const [isOpenModalValidationCode, setIsOpenModalValidationCode] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [loadingForm, setLoadingForm] = useState(true);

  const [isDeletedData, setIsDeletedData] = useState(false);
  const [isUpdateData, setIsUpdateData] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    let data = {};

    if (formFields.length > 0) {
      getUserPartner(partnerId, uId).then((userInfo) => {
        formFields.forEach((field, index) => {
          switch (field?.type) {
            case 'root':
              if (field.fieldType === 'checkbox') {
                data = {
                  ...data,
                  [field.fieldName]: userInfo[field.fieldName] ? userInfo[field.fieldName] : false,
                };
              } else if (field.fieldType === 'date') {
                const date = new Date(userInfo[field.fieldName]);

                data = {
                  ...data,
                  [field.fieldName]: convertDateDefault(date),
                };
              } else {
                data = {
                  ...data,
                  [field.fieldName]: userInfo[field.fieldName] ? userInfo[field.fieldName] : '',
                };
              }
              break;

            case 'addressList':
              if (userInfo?.addressList?.length > 0) {
                const address = userInfo?.addressList[0];

                address
                  ? (data = { ...data, addressList: { [field.fieldName]: address } })
                  : (data = { ...data, addressList: { [field.fieldName]: {} } });
              }
              break;

            case 'contactList':
              if (field.fieldType === 'email') {
                if (findValueInArray(userInfo.contactList, 'type', field.fieldName)) {
                  const dataEmail = findValueInArray(
                    userInfo.contactList,
                    'type',
                    field.fieldName
                  ).value;
                  data = {
                    ...data,
                    contactList: { ...data.contactList, [field.fieldName]: dataEmail },
                  };
                } else {
                  data = {
                    ...data,
                    contactList: { ...data.contactList, [field.fieldName]: '' },
                  };
                }
              } else if (field.fieldType === 'cellPhone') {
                if (findValueInArray(userInfo.contactList, 'type', field.fieldType)) {
                  const dataCellPhone = phone(
                    findValueInArray(userInfo.contactList, 'type', field.fieldType).value
                  );

                  const maskFilter = phoneMaskArray.filter(
                    (mask) => mask.ddd === dataCellPhone.countryCode
                  );

                  data = {
                    ...data,
                    contactList: {
                      ...data.contactList,
                      [field.fieldName]: {
                        mask: { ...maskFilter[0] },
                        phone: dataCellPhone.phoneNumber.split(maskFilter[0].ddd)[1],
                      },
                    },
                  };

                  setUserCellphone(dataCellPhone.phoneNumber);
                }
              } else {
                data = {
                  ...data,
                  contactList: {
                    ...data.contactList,
                    [field.fieldName]: findValueInArray(
                      userInfo.contactList,
                      'type',
                      field.fieldName
                    ).value,
                  },
                };
              }
              break;

            case 'documentList':
              if (field.fieldType === 'cpf') {
                if (findValueInArray(userInfo.documentList, 'type', field.fieldName)) {
                  data = {
                    ...data,
                    documentList: {
                      ...data.documentList,
                      [field.fieldName]: maskCPF(
                        findValueInArray(userInfo.documentList, 'type', field.fieldName).value
                      ),
                    },
                  };
                }
              } else if (field.fieldType === 'cnpj') {
                if (findValueInArray(userInfo.documentList, 'type', field.fieldName)) {
                  data = {
                    ...data,
                    documentList: {
                      ...data.documentList,
                      [field.fieldName]: maskCnpj(
                        findValueInArray(userInfo.documentList, 'type', field.fieldName).value
                      ),
                    },
                  };
                }
              } else {
                data = {
                  ...data,
                  documentList: {
                    ...data.documentList,
                    [field?.fieldName]: findValueInArray(
                      userInfo?.documentList,
                      'type',
                      field?.fieldType
                    )?.value,
                  },
                };
              }
              break;

            case 'optInList': {
              const optIn = `optIn_${field.optInId !== undefined ? field.optInId : ''}`;
              data = {
                ...data,
                optInList: {
                  ...data.optInList,
                  [optIn]: {
                    accept: findValueInArray(userInfo.optInList, 'optInId', field.optInId)
                      ? findValueInArray(userInfo.optInList, 'optInId', field.optInId).accept
                      : false,
                  },
                },
              };
              break;
            }

            case 'others':
              if (field.inputType === 'date') {
                const date = new Date(userInfo[field.fieldName]);
                data = {
                  ...data,
                  [field.fieldName]: convertDateDefault(date),
                };
              } else if (field.inputType === 'checkbox') {
                data = {
                  ...data,
                  [field.fieldName]: { accept: userInfo[field.fieldName] },
                };
              } else {
                data = {
                  ...data,
                  [field.fieldName]: userInfo[field.fieldName],
                };
              }
              break;

            default:
              break;
          }
          if (index === formFields.length - 1) {
            setUserInfo(userInfo);
            setFormValues(data);
            setLoadingForm(false);
          }
        });
      });
    }
  }, [formFields, formFields.length, partnerId, uId]);

  useEffect(() => {
    trackEventMatomoVisit('confirmarDados');
  }, []);

  const handleChangeCep = (cep, fieldNameAddress) => {
    if (cep.length === 9) {
      const formatedCEP = cep?.replace('-', '');
      axios
        .get(`https://viacep.com.br/ws/${formatedCEP}/json/`)
        .then((response) => {
          response = response.data;
          if (response.erro) {
            toast.error('CEP não encontrado!');
          }

          setFormValues({
            ...formValues,
            addressList: {
              ...formValues.addressList,
              [fieldNameAddress]: {
                ...formValues.addressList[fieldNameAddress],
                zipCode: cep,
                city: response.localidade,
                neighborhood: response.bairro,
                state: response.uf,
                streetAve: response.logradouro,
                streetNumber: '',
              },
            },
          });
        })
        .catch(() => {
          console.log('Erro ao obter informações do CEP');
        });
    }
  };

  function sendCode() {
    setLoading02(true);

    const sendType = {
      sms: true,
      email: true,
      whatsapp: false,
    };

    authCode_v1(null, userCellphone, sendType, null)
      .then((resultSendSMS) => {
        if (resultSendSMS.error === false) {
          setIsOpenModalValidationCode(true);
          setTransactionId(resultSendSMS.transactionId);
        } else {
          toast.error(t('errors.try_later'));
        }
      })
      .catch((error) => {
        if (error.errorCode === 1005) {
          toast.warning(t('errors.attempts_exceeded'));
          return;
        }
        toast.error(t('errors.try_later'));
      })
      .finally(() => {
        setLoading02(false);
      });
  }

  function handleDataUser(e, type) {
    e.preventDefault();
    setTypeAction(type);
    sendCode();
  }

  function handleDeleteUser(type) {
    setTypeAction(type);
    sendCode();
  }

  //ADICIONA DADOS NO PARTNER DO CLUBE
  async function addUserData(e, typeActionModal = '') {
    let cellPhoneInvalid = false;

    let userInfoObj = {};
    let addressList = {};
    let contactList = [];
    let documentList = [];
    let optInList = [];

    let stopIteration = false;

    formFields.forEach((field) => {
      if (stopIteration) return;
      switch (field.type) {
        case 'root':
        case 'others':
          if (field.fieldType === 'date') {
            if (!isValidDate(formValues[field.fieldName])) {
              setLoading(false);
              toast.warn('Por favor, insira uma data válida!');
              stopIteration = true;
            }
            userInfoObj[field.fieldName] = convertToTimestamp(formValues[field.fieldName]);
          } else {
            userInfoObj[field.fieldName] = formValues[field.fieldName];
          }
          break;

        case 'addressList':
          addressList.push({
            zipCode: formValues.addressList[field.fieldName].zipCode,
            city: formValues.addressList[field.fieldName].city,
            extra: formValues.addressList[field.fieldName].extra,
            streetAve: formValues.addressList[field.fieldName].streetAve,
            streetNumber: formValues.addressList[field.fieldName].streetNumber,
            state: formValues.addressList[field.fieldName].state,
            neighborhood: formValues.addressList[field.fieldName].neighborhood,
          });

          userInfoObj.addressList = addressList;
          break;

        case 'contactList':
          if (field.fieldType === 'cellPhone') {
            const formatMask =
              '+' + formValues.contactList[field.fieldName].mask.fullMask.replace(/\D/g, '');

            const formatCellPhone =
              formValues.contactList[field.fieldName] &&
              `${formValues.contactList[field.fieldName].mask.ddd}${formValues.contactList[
                field.fieldName
              ].phone.replace(/\D/g, '')}`;

            if (
              formValues.contactList[field.fieldName] &&
              formatCellPhone.length < formatMask.length
            ) {
              cellPhoneInvalid = true;
            } else {
              cellPhoneInvalid = false;
            }

            contactList.push({
              type: field.fieldName,
              value: formatCellPhone,
            });
          } else {
            contactList.push({
              type: field.fieldName,
              value: formValues.contactList[field.fieldName],
            });
          }

          userInfoObj.contactList = contactList;
          break;

        case 'documentList':
          if (field.fieldType === 'cpf') {
            documentList.push({
              type: field.fieldName,
              value: formValues.documentList[field.fieldName]?.replace(/\D/g, ''),
            });
          } else if (field.fieldType === 'cnpj') {
            documentList.push({
              type: field.fieldName,
              value: formValues.documentList[field.fieldName]?.replace(/\D/g, ''),
            });
          } else {
            documentList.push({
              type: field.fieldName,
              value: formValues.documentList[field.fieldName],
            });
          }

          userInfoObj.documentList = documentList;
          break;

        case 'optInList': {
          const optIn = `optIn_${field.optInId}`;
          if (formValues.optInList && formValues.optInList[optIn]?.accept) {
            optInList.push({
              accept: formValues.optInList[optIn].accept,
              dateAcceptance: Date.now(),
              optInId: field.optInId,
              type: field.label,
              version: 1,
            });
            userInfoObj.optInList = optInList;
          }
          break;
        }

        default:
          break;
      }
    });

    if (formValues.contactList.cpf && formValues.contactList.cpf.replace(/\D/g, '').length < 11) {
      setLoading(false);
      return toast.warn('Número de CPF inválido.');
    }

    if (formValues.contactList.cnpj && formValues.contactList.cnpj.replace(/\D/g, '').length < 14) {
      setLoading(false);
      return toast.warn('Número de CNPJ inválido.');
    }

    if (cellPhoneInvalid) {
      setLoading(false);
      return toast.warn('Número de celular inválido.');
    }

    userInfoObj.partnerId = infosSeuClube.partnerId;
    userInfoObj.partnerName = infosSeuClube.clubeId;
    userInfoObj.updateDate = new Date().getTime();

    if (stopIteration) return;
    editUserPartner(uId, seuClubePersist.clubeId, userInfoObj)
      .then((res) => {
        setIsUpdateData(true);
        if (typeActionModal === 'atualizar') {
          toggleModalValitaionCode();
          setLoading03(false);
        }
        setLoading(false);
        toast.success(t('general.updated_data'));
      })
      .catch((err) => {
        setLoading02(false);
        toast.error(t('errors.try_again'));
        if (typeActionModal === 'atualizar') {
          setLoading03(false);
        }
        setLoading(false);
      });
  }

  function toggleModalConfirmationDeleteData() {
    setIsOpenModal(false);
  }

  function toggleModalValitaionCode() {
    setIsOpenModalValidationCode(false);
  }

  const handleCountryChange = (e, field) => {
    //transforma a string em um array a partir da vírgula
    setFormValues({
      ...formValues,
      contactList: {
        ...formValues.contactList,
        [field.fieldName]: {
          ...formValues.contactList[field.fieldName],
          mask: {
            ...phoneMaskArray.filter((mask) => mask.ddd === e.target.value)[0],
          },
        },
      },
    });
  };

  const handleInputChange = (event, input) => {
    const name = event.target.name;
    let checked = event.target.checked;
    let value = event.target.value;
    let formattedValue = '';
    if (input.inputType === 'date') {
      value = value.replace(/\D/g, '');
      if (value.length > 0) {
        formattedValue = value.substring(0, 2);
      }
      if (value.length > 2) {
        formattedValue += '/' + value.substring(2, 4);
      }
      if (value.length > 4) {
        formattedValue += '/' + value.substring(4, 8);
      }
    }

    if (name === 'zipCode' && value.length > 5) {
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{5})(\d{1,3})/, '$1-$2');
    }

    switch (input.type) {
      case 'root':
        if (input.inputType === 'date')
          return setFormValues({ ...formValues, [name]: formattedValue });
        if (input.fieldType === 'checkbox') {
          return setFormValues({ ...formValues, [name]: checked });
        } else {
          return setFormValues({ ...formValues, [name]: value });
        }
      case 'others':
        return input.inputType === 'checkbox'
          ? setFormValues({ ...formValues, [name]: { accept: value } })
          : setFormValues({ ...formValues, [name]: value });

      case 'addressList':
        return setFormValues({
          ...formValues,
          addressList: {
            ...formValues.addressList,
            [input.fieldName]: {
              ...(formValues.addressList && formValues.addressList[input.fieldName]),
              [name]: value,
            },
          },
        });

      case 'contactList':
        return input.fieldType === 'email'
          ? setFormValues({
              ...formValues,
              contactList: { ...formValues.contactList, [name]: value },
            })
          : input.fieldType === 'cellPhone'
          ? setFormValues({
              ...formValues,
              contactList: {
                ...formValues.contactList,
                [name]: {
                  ...formValues.contactList[name],
                  mask: !formValues.contactList[name]?.mask
                    ? {
                        ...phoneMaskArray.filter((mask) => mask.ddd === '+55')[0],
                      }
                    : { ...formValues.contactList[name].mask },
                  phone: value.replace(/\D/g, ''),
                },
              },
            })
          : setFormValues({
              ...formValues,
              contactList: { ...formValues.contactList, [name]: value },
            });

      case 'documentList':
        return input.fieldType === 'cpf'
          ? setFormValues({
              ...formValues,
              documentList: { ...formValues.documentList, [name]: maskCPF(value) },
            })
          : input.fieldType === 'cnpj'
          ? setFormValues({
              ...formValues,
              documentList: { ...formValues.documentList, [name]: maskCnpj(value) },
            })
          : setFormValues({
              ...formValues,
              documentList: { ...formValues.documentList, [name]: value },
            });

      case 'optInList': {
        const optIn = `optIn_${input.optInId !== undefined ? input.optInId : ''}`;
        return setFormValues({
          ...formValues,
          optInList: { ...formValues.optInList, [optIn]: { accept: checked } },
        });
      }
      default:
        break;
    }
  };

  const renderInputs = (input) => {
    const validationsInput = {
      aliasName: '12',
    };
    const inputValue = formValues[input.fieldName];
    switch (input.type) {
      case 'root':
      case 'others':
        if (input.fieldType === 'checkbox') {
          return (
            <S.ContainerInput>
              <FormGroup>
                <FormControlLabel
                  checked={formValues ? formValues[input.fieldName] : false}
                  onChange={(e) => handleInputChange(e, input)}
                  id={input.fieldName}
                  name={input.fieldName}
                  required={input.required}
                  disabled={
                    !formValues[input.fieldName] || formValues[input.fieldName] === ''
                      ? false
                      : !input.allowEdition
                  }
                  control={<Checkbox />}
                  label={input.label}
                  style={{ margin: 0 }}
                />
              </FormGroup>
            </S.ContainerInput>
          );
        } else {
          if (input.inputType === 'date') {
            return (
              <Input
                type={'others'}
                label={input.required ? input.label + '*' : input.label}
                value={inputValue?.includes('NaN') ? '' : inputValue}
                handleChange={(e) => handleInputChange(e, input)}
                id={input.fieldName}
                name={input.fieldName}
                required={input.required}
                maxLength={10}
                disabled={!inputValue === '' ? false : !input.allowEdition}
              />
            );
          }
          return (
            <Input
              type={input.inputType}
              label={input.required ? input.label + '*' : input.label}
              value={inputValue}
              handleChange={(e) => handleInputChange(e, input)}
              id={input.fieldName}
              name={input.fieldName}
              required={input.required}
              maxLength={validationsInput[input.fieldName] || Infinity}
              disabled={!inputValue === '' ? false : !input.allowEdition}
            />
          );
        }
      case 'addressList':
        return (
          <S.ContentAddress>
            <h3>{input.fieldName}:</h3>
            <Input
              type="text"
              label="Cep do Endereço*"
              value={
                formValues.addressList !== undefined
                  ? formValues.addressList[input.fieldName]?.zipCode
                  : ''
              }
              handleChange={(e) => {
                handleInputChange(e, input);
                handleChangeCep(e.target.value, input.fieldName);
              }}
              id="cep"
              name="zipCode"
              required
              maxLength="9"
            />
            <S.ContentAgroupInputAddres>
              <Input
                type="text"
                label="Rua*"
                value={
                  formValues.addressList !== undefined
                    ? formValues.addressList[input.fieldName]?.streetAve
                    : ''
                }
                disabled={
                  formValues.addressList
                    ? formValues.addressList[input.fieldName]?.streetAve
                      ? true
                      : false
                    : false
                }
                handleChange={(e) => handleInputChange(e, input)}
                id="rua"
                name="streetAve"
                required
              />
              <Input
                type="text"
                label="Número*"
                value={
                  formValues.addressList !== undefined
                    ? formValues.addressList[input.fieldName]?.streetNumber
                    : ''
                }
                handleChange={(e) => handleInputChange(e, input)}
                id="numero"
                name="streetNumber"
                required
              />
            </S.ContentAgroupInputAddres>
            <S.ContentAgroupInputAddres>
              <Input
                type="text"
                label="Bairro*"
                value={
                  formValues.addressList !== undefined
                    ? formValues.addressList[input.fieldName]?.neighborhood
                    : ''
                }
                disabled={
                  formValues.addressList
                    ? formValues.addressList[input.fieldName]?.neighborhood
                      ? true
                      : false
                    : false
                }
                handleChange={(e) => handleInputChange(e, input)}
                id="bairro"
                name="neighborhood"
                required
              />
              <Input
                type="text"
                label="Cidade*"
                value={
                  formValues.addressList !== undefined
                    ? formValues.addressList[input.fieldName]?.city
                    : ''
                }
                disabled={
                  formValues.addressList
                    ? formValues.addressList[input.fieldName]?.city
                      ? true
                      : false
                    : false
                }
                handleChange={(e) => handleInputChange(e, input)}
                id="cidade"
                name="city"
                required
              />
              <FormControl
                sx={{
                  display: 'flex',
                  height: '71px',
                  justifyContent: 'space-between',
                }}
                fullWidth
              >
                <label>UF*</label>
                <Select
                  id="uf-select"
                  value={
                    formValues.addressList !== undefined
                      ? formValues.addressList[input.fieldName]?.state?.toUpperCase() || ''
                      : ''
                  }
                  disabled={
                    formValues.addressList
                      ? formValues.addressList[input.fieldName]?.state?.toUpperCase()
                        ? true
                        : false
                      : false
                  }
                  onChange={(e) => handleInputChange(e, input)}
                  required
                  name="state"
                  sx={{
                    height: '42px',
                    width: '100%',
                    borderRadius: '10px',
                    padding: '0.7rem',
                    outline: 'none',
                  }}
                  placeholder="UF"
                >
                  {UFs.map((uf) => (
                    <MenuItem key={uf} value={uf}>
                      {uf}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </S.ContentAgroupInputAddres>
            <Input
              type="text"
              label="Complemento"
              value={
                formValues.addressList !== undefined
                  ? formValues.addressList[input.fieldName]?.extra
                  : ''
              }
              handleChange={(e) => handleInputChange(e, input)}
              id="complemento"
              name="extra"
            />
          </S.ContentAddress>
        );

      case 'contactList':
        return input.fieldType === 'cellPhone' ? (
          <S.ContainerInput>
            <label>
              {input.label}
              {input.required && '*'}
            </label>
            <S.BoxCellPhone>
              <S.Adornment>
                <select
                  value={
                    formValues.contactList
                      ? formValues.contactList[input.fieldName]?.mask.ddd
                      : '+55'
                  }
                  disabled={
                    userInfo?.contactList[input.fieldName]?.mask?.ddd?.length === 0
                      ? false
                      : !input.allowEdition
                  }
                  onChange={(e) => handleCountryChange(e, input)}
                >
                  {phoneMaskArray.map((country, index) => (
                    <option
                      style={{
                        fontFamily: 'Source Sans Pro, sans-serif',
                      }}
                      key={country.id}
                      value={country.ddd}
                    >
                      {country.initials} {country.ddd}
                    </option>
                  ))}
                </select>
              </S.Adornment>

              <S.Input
                mask={
                  formValues.contactList &&
                  formValues.contactList[input.fieldName] &&
                  formValues.contactList[input.fieldName].mask
                    ? formValues.contactList[input.fieldName].mask.mask
                    : '(99) 99999-9999'
                }
                value={
                  formValues.contactList &&
                  formValues.contactList[input.fieldName] &&
                  formValues.contactList[input.fieldName].phone
                    ? formValues.contactList[input.fieldName].phone
                    : ''
                }
                placeholder={
                  formValues.contactList
                    ? formValues.contactList[input.fieldName]?.mask.mask
                    : randomizeNumbersString('(99) 99999-9999')
                }
                onChange={(e) => handleInputChange(e, input)}
                id={input.fieldName}
                name={input.fieldName}
                required={input.required}
                disabled={
                  !formValues?.contactList[input?.fieldName]?.phone ||
                  formValues?.contactList[input?.fieldName]?.phone === ''
                    ? false
                    : !input?.allowEdition
                }
              />
            </S.BoxCellPhone>
          </S.ContainerInput>
        ) : (
          <Input
            type={input.inputType}
            label={input.required ? input.label + '*' : input.label}
            value={formValues.contactList && formValues.contactList[input.fieldName]}
            handleChange={(e) => handleInputChange(e, input)}
            id={input.fieldName}
            name={input.fieldName}
            required={input.required}
            disabled={
              !formValues.contactList[input.fieldName] ||
              formValues.contactList[input.fieldName] === ''
                ? false
                : !input.allowEdition
            }
          />
        );

      case 'documentList':
        return (
          <Input
            mask={
              input.fieldType === 'cpf'
                ? '999.999.999-99'
                : input.fieldType === 'cnpj'
                ? '99.999.999/9999-99'
                : null
            }
            type={input.inputType}
            label={input.required ? input.label + '*' : input.label}
            value={formValues.documentList && formValues.documentList[input.fieldName]}
            handleChange={(e) => handleInputChange(e, input)}
            id={input.fieldName}
            name={input.fieldName}
            required={input.required}
            disabled={
              formValues.documentList
                ? !formValues.documentList[input.fieldName] ||
                  formValues.documentList[input.fieldName] === ''
                  ? false
                  : !input.allowEdition
                : false
            }
          />
        );
      case 'optInList':
        return (
          <S.ContainerInput>
            <FormGroup>
              <FormControlLabel
                checked={
                  formValues.optInList
                    ? formValues.optInList[`optIn_${input.optInId}`]?.accept
                    : false
                }
                onChange={(e) => handleInputChange(e, input)}
                id={input.type === 'optInList' ? `optIn_${input.optInId}` : input.fieldName}
                name={input.type === 'optInList' ? `optIn_${input.optInId}` : input.fieldName}
                required={input.required}
                disabled={
                  !formValues.optInList[`optIn_${input.optInId}`]?.accept ||
                  formValues.optInList[`optIn_${input.optInId}`]?.accept === ''
                    ? false
                    : !input.allowEdition
                }
                control={<Checkbox />}
                label={input.label}
                style={{ margin: 0 }}
              />
            </FormGroup>
          </S.ContainerInput>
        );

      default:
        break;
    }
  };

  if (loading) {
    return <LoadingInfo />;
  } else if (messageErrorApi !== '' && !loading) {
    return <ErrorMessage message={messageErrorApi} />;
  }

  return (
    <S.Container className="paper-container">
      {isDeletedData || isUpdateData ? (
        <StatusMessage
          userUid={uId}
          isUpdateData={isUpdateData}
          userCellphone={userCellphone}
          typeAction={typeAction}
        />
      ) : (
        <>
          <S.AsideContainer $bgColor={infosSeuClube.colorPrimary}>
            <img src={imgIllustration} alt="Imagem formulário" />

            <strong>{TITLE}</strong>
            <p style={{ whiteSpace: 'pre-line' }}>{DESCRIPTION}</p>
          </S.AsideContainer>
          <S.FormContainer>
            <S.FormContainerTitle>
              <strong>{TITLE}</strong>
              <p style={{ whiteSpace: 'pre-line' }}>{DESCRIPTION}</p>
            </S.FormContainerTitle>
            <S.ClubeLogo $bgColor={infosSeuClube.colorPrimary}>
              <img
                src={cfac22('STORAGE_URL') + 'seuClube%2F' + infosSeuClube.logo + '?alt=media'}
                alt="Triibo"
              />
            </S.ClubeLogo>

            <S.Form onSubmit={(e) => handleDataUser(e, 'atualizar')}>
              {loadingForm ? (
                <CircularProgress style={{ color: colorText(seuClubePersist.colorPrimary) }} />
              ) : (
                <S.FormFieldsContainer>
                  {formFields.map((field, index) => (
                    <S.Field key={index}>
                      {renderInputs(field, index)}
                      <br />
                      {field.type === 'optInList' && (
                        <a href={field.link} target="_blank" rel="noreferrer">
                          Ver Termos de Uso
                        </a>
                      )}
                    </S.Field>
                  ))}
                </S.FormFieldsContainer>
              )}

              <S.Button
                onClick={() =>
                  trackEventMatomo('confirmarDados', 'tocar', 'button', 'Atualizar dados')
                }
                type="submit"
                $bgColor={infosSeuClube.colorPrimary}
                disabled={loading02 && !isOpenModalValidationCode && typeAction === 'atualizar'}
              >
                {loading02 && !isOpenModalValidationCode && typeAction === 'atualizar' ? (
                  <CircularProgress
                    style={{ color: colorText(infosSeuClube.colorPrimary) }}
                    size="2rem"
                  />
                ) : (
                  t('general.update_data')
                )}
              </S.Button>

              <S.Button
                $bgColor="red"
                type="button"
                disabled={loading02 && !isOpenModalValidationCode && typeAction === 'excluir'}
                onClick={() => {
                  handleDeleteUser('excluir');
                  trackEventMatomo('confirmarDados', 'tocar', 'button', 'Apagar meus dados');
                }}
              >
                {loading02 && !isOpenModalValidationCode && typeAction === 'excluir' ? (
                  <CircularProgress
                    style={{ color: colorText(infosSeuClube.colorPrimary) }}
                    size={20}
                  />
                ) : (
                  t('general.reques_deletion')
                )}
              </S.Button>
            </S.Form>
          </S.FormContainer>
        </>
      )}

      {/* MODAL DE CONFIRMAÇÃO DE EXLUSÃO DE DADOS*/}
      {isOpenModal && (
        <ModalConfirmationDeletedData
          toggleModal={toggleModalConfirmationDeleteData}
          setStatusData={() => {
            setIsDeletedData(true);
          }}
          open={isOpenModal}
          userUid={uId}
        />
      )}

      {/* MODAL DE CONFIRMAÇÃO DE CÓDIGO */}
      {isOpenModalValidationCode && (
        <ModalValidationCode
          typeAction={typeAction}
          setLoading={setLoading03}
          loading03={loading03}
          loading02={loading02}
          addUserData={addUserData}
          sendCode={sendCode}
          transactionId={transactionId}
          toggleModal={toggleModalValitaionCode}
          open={isOpenModalValidationCode}
          userCellphone={userCellphone}
          setIsOpenModal={setIsOpenModal}
        />
      )}
    </S.Container>
  );
}

export default DataConfirmation;

