import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Rating, CircularProgress, DialogContent } from '@mui/material';
import { Star } from '@mui/icons-material';
import { CustomizedModal } from 'js/containers/Dialog/CustomizedModal';

import { toast } from 'react-toastify';

import establishmentRatings from 'js/library/utils/API/establishmentRatings';

import './modalRatingsStyles.css';
import { colorText, trackEventMatomo } from 'js/library/utils/helpers';
import { theme } from 'index';

export function ModalRatings(props) {
  const { t } = useTranslation();

  const [userComment, setUserComment] = useState(props.userMessage);
  const [loading, setLoading] = useState(false);

  async function sendRating() {
    const { establishmentInfo, userInfo, ratingNumber } = props;
    const establishmentId = establishmentInfo.id;
    const uId = userInfo.uId;

    if (userComment.trim() === '') {
      toast.warning(t('errors.field_not_filled'));
      return;
    }

    setLoading(true);

    try {
      await establishmentRatings(
        uId,
        establishmentId,
        ratingNumber,
        userComment,
        userInfo.aliasName
      );
      toast.success(t('general.review_sent_successfully'));
      props.toggleModalRatings();
      setLoading(false);
      setUserComment('');
    } catch (error) {
      //console.log('Error', error);
      setLoading(false);
    }
  }

  return (
    <CustomizedModal open={props.open} keepMounted onClose={props.toggleModalRatings}>
      <DialogContent className="content-modal-rate">
        <div className="content-icon-rate">
          <Star style={{ color: '#fff', fontSize: '2.2rem' }} />
        </div>

        <Rating
          name="read-only"
          precision={0.5}
          readOnly
          style={{ fontSize: '2.5rem', marginTop: '40px' }}
          value={props.ratingNumber}
        />

        <textarea
          onChange={(e) => setUserComment(e.target.value)}
          value={userComment}
          rows="4"
          cols="50"
          className="input-modal-ratings"
          type="text"
          placeholder={t('general.leave_your_comment_here')}
        />

        <div className="area-button-modal">
          <button
            onClick={() => {
              props.toggleModalRatings();
              trackEventMatomo('Estabelecimento', 'tocar', 'botao', 'Cancelar');
            }}
            type="button"
            className="button-modal-rate"
          >
            {t('general.cancel')}
          </button>
          <button
            onClick={() => {
              sendRating();
              trackEventMatomo('Estabelecimento', 'tocar', 'botao', 'Avaliar');
            }}
            type="button"
            className="button-modal-rate"
          >
            {loading ? (
              <CircularProgress
                style={{ color: colorText(theme.palette.primary.main) }}
                size={20}
              />
            ) : (
              t('general.rate')
            )}
          </button>
        </div>
      </DialogContent>
    </CustomizedModal>
  );
}
