import styled from 'styled-components';
const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

export const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
  }
`;

export const Button = styled.button`
  height: 42px;
  font-size: 16px;
  border-radius: 10px;
  padding: 0 1rem;
  border: none;
  cursor: pointer;
  background: ${seuClubePersist ? seuClubePersist.colorPrimary : '#ccc'};
  color: ${seuClubePersist ? seuClubePersist.colorSecondary : '#fff'};
  font-weight: 600;

  :disabled {
    cursor: default;
    background-color: #ccc;
  }
`;
