import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, CircularProgress, Typography } from '@mui/material';
import './reports.css';

import { getLastUserInfo, getUrlVariables } from 'js/library/utils/helpers';
import { checkUserStatus, logout } from 'js/library/services/AuthenticationManager.js';

import { models } from 'powerbi-client';
import getPowerBI from 'js/library/utils/API/getPowerBI';
import { PowerBIEmbed } from 'powerbi-client-react';

export default function Reports() {
  const [embedUrl] = useState('');
  const [openGraphics, setOpenGraphics] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [reportList, setReportList] = useState([]);
  const [accessToken, setAccessToken] = useState(null);

  //verificações
  const [errorMsg, setErrorMsg] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');
  const [error, setError] = useState(false);
  const [reload, setReload] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const userInfo = getLastUserInfo();
    const urlVars = getUrlVariables();

    checkUserStatus()
      .then(async (resp) => {
        if (resp !== null) {
          if (resp.uid === userInfo.uId && resp.uid === urlVars.uid) {
            getPowerBI(urlVars.configId, urlVars.uid)
              .then((response) => {
                let list = [];
                if (response.success) {
                  response.embedInfo.forEach((item) => {
                    let data = {
                      ...item.embedUrl[0],
                      accessToken: item.accessToken,
                    };
                    list.push(data);
                  });
                }
                //setUrlGraphics(response.embedInfo[0].embedUrl[0].embedUrl);
                setReportId(response.embedInfo[0].embedUrl[0].reportId);
                setAccessToken(response.embedInfo[0].accessToken);
                setOpenGraphics(true);
                setReportList(list);
              })
              .catch((e) => {
                setErrorMsg(t('errors.no_BI_permission'));
                setButtonUrl('/');
                setError(true);
              });
          } else {
            //uid da URL diferente da conta logada - deslogando o usuário e logando na conta da URL
            await logout();
            setReload(!reload);
          }
        } else {
          //usuario deslogado
          setError(true);
          setButtonUrl('/validarAcesso');
          setErrorMsg(t('errors.user_not_logged_in'));

          sessionStorage.setItem('loginOption', 3);
          sessionStorage.setItem(
            'loginRedirect',
            window.location.pathname + window.location.search
          );
        }
      })
      .catch((e) => {
        setErrorMsg(t('errors.error_occurred'));
        setButtonUrl('/');
        setError(true);
      });
  }, [t, reload]);

  const filterGraphic = (reportId) => {
    setOpenGraphics(false);
    if (reportId !== '') {
      const selectedGraphic = reportList.filter((item) => item.reportId === reportId);
      if (selectedGraphic.length > 0) {
        //setUrlGraphics(selectedGraphic[0].embedUrl);
        setReportId(selectedGraphic[0].reportId);
        setAccessToken(selectedGraphic[0].accessToken);
        setOpenGraphics(true);
      }
    }
  };

  return (
    <div>
      {reportList.length > 1 && (
        <select
          name="Reports BI"
          className="selectBi"
          style={{ height: '3vh' }}
          onChange={(event) => filterGraphic(event.target.value)}
        >
          {reportList.map((item, index) => {
            return (
              <option key={index} value={item.reportId}>
                {item.reportName}
              </option>
            );
          })}
        </select>
      )}
      <div className="labelSelect">{t('general.select_campaign')}</div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {openGraphics ? (
          <div style={{ height: reportList.length > 1 ? '89vh' : '96vh', width: '100%' }}>
            <PowerBIEmbed
              cssClassName="power-bi"
              key={reportId}
              embedConfig={{
                type: 'report', // Supported types: report, dashboard, tile, visual and qna
                id: reportId,
                embedUrl: embedUrl,
                accessToken: accessToken, // Keep as empty string, null or undefined
                tokenType: models.TokenType.Embed,
              }}
            />
          </div>
        ) : error ? (
          <div style={{ padding: 10 }}>
            <Typography variant="h4" gutterBottom>
              {errorMsg}
            </Typography>
            <div style={{ textAlign: 'center' }}>
              <Link to={buttonUrl}>
                <Button variant="contained" color="primary" style={{ textTransform: 'none' }}>
                  Voltar para o clube
                </Button>
              </Link>
            </div>
          </div>
        ) : (
          <CircularProgress />
        )}
      </div>
    </div>
  );
}

