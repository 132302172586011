import React from 'react';
import * as S from './styles';

export default function Checkbox({
  label,
  value,
  checked,
  handleChange,
  ...props
}) {
  return (
    <S.Label>
      <S.InputCheckbox
        value={value}
        checked={checked}
        onChange={handleChange}
        {...props}
      />
      {label}
    </S.Label>
  );
}
