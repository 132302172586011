import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from 'js/library/utils/cfac22/cfac22';

export async function validateCode(code, cellPhone, transactionId) {
  return new Promise((resolve, reject) => {
    authToken()
      .then((token) => {
        //console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', result);

        axios
          .post(
            cfac22('API_HOST_V2') + '/sms/validateSMS',
            {
              transactionId: transactionId,
              cellphone: cellPhone,
              code: code,
            },
            {
              headers: { authorization: `Bearer ${token}` },
            }
          )
          .then((result) => {
            // console.log('RESULTADO VALIDATE SMS ===>', result);
            resolve(result.data);
          })
          .catch((error) => {
            //console.log('ERROR VALIDATE SMS ===>', error);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
        reject(error);
      });
  });
}
