import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import cfac22 from 'js/library/utils/cfac22/cfac22';

const containerStyle = {
  width: '90%',
  height: '350px',
};

function GoogleMaps({ establishmentInfo }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: cfac22('FIREBASE_APIKEY'),
  });

  const center = {
    lat: parseFloat(establishmentInfo.lat, 10),
    lng: parseFloat(establishmentInfo.long, 10),
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  // const [map, setMap] = React.useState(null)

  // const onLoad = React.useCallback(function callback(map) {
  //     const bounds = new window.google.maps.LatLngBounds();
  //     map.fitBounds(bounds);
  //     setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //     setMap(null)
  // }, [])

  return isLoaded ? (
    <GoogleMap
      id="map"
      mapContainerStyle={containerStyle}
      center={center}
      options={options}
      zoom={16}

      // onLoad={onLoad}
      // onUnmount={onUnmount}
    >
      <Marker position={center} />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default GoogleMaps;
