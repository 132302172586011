import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { getLastUserInfo } from '../helpers';

export default function getVoucherById(id, extraParams) {
  const userInfo = getLastUserInfo();
  return new Promise((resolve, reject) => {
    authToken(userInfo.uId).then((token) => {
      var config = {
        method: 'POST',
        url: `${cfac22('API_HOST_V2')}/vouchers/getVoucherById/?voucherId=${id}`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
        data: {
          models: extraParams,
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error.response.data);
        });
    });
  });
}
