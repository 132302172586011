import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { firebaseAuth } from 'js/library/utils/firebaseUtils';

export function logout() {
  return new Promise((resolve) => {
    signOut(firebaseAuth).then(function (logout) {
      localStorage.clear();
      resolve(logout);
    });
  });
}

export function checkUserStatus() {
  return new Promise((resolve) => {
    onAuthStateChanged(firebaseAuth, (user) => {
      return resolve(user);
    });
  });
}

export function loginUser(email, password) {
  return new Promise((resolve, reject) => {
    password = decodeURIComponent(password);
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then(function (login) {
        return resolve(login);
      })
      .catch(function (error) {
        // console.log(error);
        return reject(error);
      });
  });
}

