import axios from 'axios';
import { getChannelToken_v1 } from 'js/library/utils/API/getChannelToken_v1';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { loginUser } from 'js/library/services/AuthenticationManager.js';

export async function setUserInfo_v1(uId, queryPartnerAPI, userInfo, path) {
  return new Promise(function (resolve, reject) {
    getChannelToken_v1(uId)
      .then((result) => {
        axios
          .post(cfac22('API_HOST_V1') + 'setUserInfo_v1', {
            triiboHeader: {
              apiToken: cfac22('apiToken'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: uId,
              channelTokenId: result,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then(async (result) => {
            //console.log('RESULTADO SET USER ===>', result);

            //LÓGICA PARA ENCAMINHAR PARA URL DINÂMICA
            if (
              localStorage.getItem('loginOption') === '3' &&
              localStorage.getItem('loginRedirect') !== undefined &&
              path !== null
            ) {
              window.pathname = localStorage.getItem('loginRedirect');
            } else {
              window.pathname = document.location.origin + '';
            }

            //logando usuário no firebase
            localStorage.removeItem('userInfoAuxiliar');
            await loginUser(
              result.data.success.newUserInfo.triiboId.replace(/[,]/gi, '.'),
              result.data.success.newUserInfo.passPhrase
            );
            resolve({ userInfo: result.data.success.newUserInfo });
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
        reject(error);
      });
  });
}

export async function setUserInfo_v1Default(uId, queryPartnerAPI, userInfo) {
  return new Promise(function (resolve, reject) {
    getChannelToken_v1(uId)
      .then((result) => {
        axios
          .post(cfac22('API_HOST_V1') + 'setUserInfo_v1', {
            triiboHeader: {
              apiToken: cfac22('apiToken'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: uId,
              channelTokenId: result,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then(async (result) => {
            //console.log('RESULTADO SET USER ===>', result);

            resolve({ userInfo: result.data.success.newUserInfo });
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
        reject(error);
      });
  });
}
