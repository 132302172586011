import React, { useEffect, useState } from 'react';
import BalanceItem from 'js/containers/Balance/BalanceItem';
import { CircularProgress, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { MagnifyingGlass } from '@phosphor-icons/react';

export const BalanceGridActive = ({ coupons }) => {
  const [filter, setFilter] = useState('');
  const [filteredCoupons, setFilteredCoupons] = useState(coupons);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let timeDebounce;
    timeDebounce = setTimeout(() => {
      setFilteredCoupons(
        coupons.filter((i) => {
          return (
            i.establishmentName.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
            i.title.toLowerCase().indexOf(filter.toLowerCase()) > -1
          );
        })
      );

      setLoading(false);
    }, 900);

    return () => {
      clearTimeout(timeDebounce);
    };
  }, [filter, coupons]);

  return (
    <Grid container spacing={3} rowSpacing={5} sx={{ marginBottom: '20px' }}>
      <Grid item xs={12} sx={{ margin: '15px 0px' }}>
        <TextField
          fullWidth
          label="Pesquisar cupons"
          variant="outlined"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MagnifyingGlass size={32} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {loading ? (
        <Grid item xs={12} textAlign={'center'}>
          <CircularProgress size={40} />
        </Grid>
      ) : filteredCoupons.length > 0 ? (
        filteredCoupons.map((item) => (
          <Grid item xs={12} sm={6} key={item.establishmentId + '-' + item.key ?? item.sentDate}>
            <BalanceItem filter={filter} voucher={item} />
          </Grid>
        ))
      ) : (
        <Typography variant="h6" textAlign="center">
          nenhum cupom encontrado
        </Typography>
      )}
    </Grid>
  );
};
export default BalanceGridActive;
