import { styled } from 'styled-components';

export const ProductImage = styled.img`
  width: 100%;
  /* max-height: 400px; */
  /* height: 200px; */
`;

export const ProductTitle = styled.h3``;

export const ProductDescription = styled.p``;
