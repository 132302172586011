import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, CircularProgress, Grid, Typography } from '@mui/material';

import { colorText, decrypt } from 'js/library/utils/helpers';
import { getUserInfoBasic_v1 } from 'js/library/utils/API/getUserInfo_v1';
import { loginUser } from 'js/library/services/AuthenticationManager';
import { getUserPartner } from 'js/library/utils/API/apiGetUserPartner';
import { theme } from 'index';
import cfac22 from 'js/library/utils/cfac22/cfac22';

class Entrance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      destiny: null,
      success: null,
    };

    try {
      //buscando variáveis da URL
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      const userId = urlParams.get('userId');
      const destiny = urlParams.get('destiny');

      //descriptografando valor
      const cellPhone = decrypt(userId, cfac22('CRYPTO_KEY'));

      //obj de pesquisa
      const userInfo = {
        contactList: [
          {
            type: 'cellPhone',
            value: cellPhone,
          },
        ],
      };

      const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
      const partnerId = `${seuClubePersist.id}_${seuClubePersist.clubeId}`;

      //buscando usuário
      getUserInfoBasic_v1(['getUserInfo'], userInfo).then(async (result) => {
        if (result !== false) {
          getUserPartner(partnerId, result.uId)
            .then(async (response) => {
              await loginUser(result.triiboId.replace(/[,]/gi, '.'), result.passPhrase);
              localStorage.setItem(
                'userInfoAuxiliar',
                JSON.stringify({
                  ...response,
                  triiboId: result.triiboId,
                  uId: result.uId,
                })
              );
              this.setState({ destiny: destiny, success: true });
            })
            .catch((error) => {
              this.setState({ success: false });
            });
        } else {
          this.setState({ success: false });
        }
      });
    } catch (error) {
      this.setState({ success: false });
    }
  }

  render = () => {
    const { t } = this.props;

    if (this.state.success === true) {
      window.location = this.state.destiny;
    } else {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          align="center"
          className="background-login paper-container"
        >
          <Grid item md={4} sm={6} xs={10}></Grid>

          <Grid item md={4} sm={6} xs={10}>
            {this.state.success === null ? (
              <>
                <CircularProgress style={{ color: colorText(theme.palette.primary.main) }} />
                <Typography variant="h6">{t('general.wait_we_redirect_you')}</Typography>
              </>
            ) : (
              <>
                <Typography variant="h6">{t('errors.try_again')}</Typography>
                <a href={cfac22('homepage')} style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary" style={{ textTransform: 'none' }}>
                    {t('general.go_back_triibo')}
                  </Button>
                </a>
              </>
            )}
          </Grid>

          <Grid item md={4} sm={6} xs={10}></Grid>
        </Grid>
      );
    }
  };
}

export default withTranslation()(Entrance);
