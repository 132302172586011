import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import { CircularProgress, TextField } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';

import { validateCode } from 'js/library/utils/API/validateSMS';

import { toast } from 'react-toastify';

import './stylesDataConfirmation.css';
import { colorText } from 'js/library/utils/helpers';
import { theme } from 'index';
import { SignUpContext } from 'js/context/SignUpContext';

export function ModalValidationCode(props) {
  const { t } = useTranslation();
  const { infosSeuClube } = useContext(SignUpContext);

  const [validationCode, setValidationCode] = useState('');
  const [messageError, setMessageError] = useState('');
  const [startTime, setStartTime] = useState(false);
  const [resetTime, setResetTime] = useState(false);

  useEffect(() => {
    setValidationCode('');
    setMessageError('');
    setStartTime(false);
  }, []);

  function validationSMS(event) {
    event.preventDefault();
    props.setLoading(true);

    validateCode(validationCode, props.userCellphone, props.transactionId)
      .then((result) => {
        if (result.success) {
          if (props.typeAction === 'atualizar') {
            props.addUserData(props.typeAction);
            props.setLoading(false);
            props.toggleModal();
          } else {
            props.toggleModal();
            props.setIsOpenModal(true);
            props.setLoading(false);
          }
        } else {
          toast.error(t('errors.error_ocurred'));
          setMessageError('');
          props.setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 422) {
          toast.warning(t('errors.invalid_code'));
          setMessageError(t('errors.invalid_code'));
          props.setLoading(false);
        } else {
          toast.error(t('errors.error_ocurred'));
          setMessageError('');
          props.setLoading(false);
        }
      });
  }

  function handleChangeInput(event) {
    const { value } = event.target;
    setMessageError('');

    if (value.length < 7) {
      setValidationCode(value);
    }
  }

  useEffect(() => {
    let time = setTimeout(() => {
      setStartTime(true);
      setResetTime(false);
    }, 17000);

    return () => {
      setStartTime(false);
      clearTimeout(time);
    };
  }, [resetTime]);

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.loading03 ? null : props.toggleModal}
      sx={{
        paper: {
          overflowY: 'inherit',
        },
      }}
    >
      <DialogContent className="content-modal-delete-data">
        <header className="header-modal-delete-data">
          <strong>{t('general.authentication_code')}</strong>
          <CloseIcon
            style={{ color: infosSeuClube.colorPrimary }}
            onClick={() => {
              if (!props.loading03) props.toggleModal();
            }}
          />
        </header>

        <form
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          onSubmit={validationSMS}
        >
          <main className="main-modal-delete-data">
            <p>{t('general.you_will_receive_auth_code_in_seconds')}</p>
            <p style={{ fontSize: '13px' }}>
              {t('general.authentication_code_sent_to')} {props.userCellphone}
            </p>
            {messageError !== '' && (
              <span style={{ color: 'red', fontSize: '12px', marginBottom: '10px' }}>
                {messageError}
              </span>
            )}

            <TextField
              fullWidth
              autoFocus
              required
              autoComplete="off"
              value={validationCode}
              onChange={(event) => handleChangeInput(event)}
              type="tel"
              id="code"
              label="Código"
            />
          </main>

          <div className="area-button-modal-delete-data-02">
            <button
              disabled={validationCode.length < 6 || props.loading03}
              className={`${
                validationCode.length < 6
                  ? 'button-modal-delete-data disabled-button-modal-validation'
                  : 'button-modal-delete-data'
              }`}
              type="submit"
              style={{ background: infosSeuClube.colorPrimary }}
            >
              {props.loading03 ? (
                <CircularProgress
                  style={{ color: colorText(theme.palette.primary.main) }}
                  size={20}
                />
              ) : (
                t('general.confirm')
              )}
            </button>

            {startTime && !props.loading03 && (
              <button
                disabled={props.loading02}
                className="button-modal-delete-data-02"
                onClick={() => {
                  props.sendCode();
                  setResetTime(!resetTime);
                  setStartTime(false);
                }}
                type="button"
              >
                {props.loading02 ? (
                  <CircularProgress
                    style={{ color: colorText(theme.palette.primary.main) }}
                    size={20}
                  />
                ) : (
                  <span style={{ color: theme.palette.primary.main }}>
                    {t('general.send_authentication_code_again')}
                  </span>
                )}
              </button>
            )}
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
