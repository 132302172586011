import React from 'react';
import { useTranslation } from 'react-i18next';

import { Instagram, Facebook, Twitter, LinkedIn } from '@mui/icons-material';

function SocialMedia({ redesSociais }) {
  const { t } = useTranslation();

  function renderIcon(type) {
    switch (type) {
      case 'instagram':
        return <Instagram />;
      case 'facebook':
        return <Facebook />;
      case 'twitter':
        return <Twitter />;
      case 'linkedin':
        return <LinkedIn />;
      default:
        return null;
    }
  }

  return (
    <div>
      <div className="text-social-media-estab-links">
        <span>
          {redesSociais.length === 1 ? t('general.social_medias') : t('general.social_media')}
        </span>
      </div>

      <div className="content-social-media-estab-links">
        {redesSociais.map(({ type, value }, index) => (
          <a key={index} href={value} rel="noreferrer" target="_blank">
            {renderIcon(type)}
          </a>
        ))}
      </div>
    </div>
  );
}

export default SocialMedia;
