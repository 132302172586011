import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import readEstablishment from 'js/library/utils/API/readEstablishment';
import { downloadImage } from 'js/library/services/StorageManager.js';
import placeholderThumbnail from 'styles/assets/placeholder/placeholder.gif';
import androidImg from 'styles/assets/Download/download_android.png';
import IOS_img from 'styles/assets/Download/download_ios.png';

import BusinessContact from './BusinessContact';
import SocialMedia from './SocialMedia';

import LoadingInfoEstablishment from './LoadingInfoEstablishment';
import ErrorMessageEstablishmentLinks from './ErrorMessageEstablishmentLinks';
import './styleEstablishmentLinks.css';

function EstablishmentLinks() {
  const { t } = useTranslation();

  const [establishmentData, setEstablishmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isErrorApi, setIsErrorApi] = useState(false);
  const [message, setMessage] = useState('');
  const [isBackgroundImg /* setIsbackgroundImg */] = useState(false);
  const [loadingThumbnail, setLoadingThumbnail] = useState(false);

  useEffect(() => {
    const getEstablishmentInfo = async () => {
      try {
        //PEGA PARAMETROS DA URL
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const establishmentId = urlParams.get('id');

        if (!establishmentId) {
          setIsErrorApi(true);
          setLoading(false);
          return;
        }

        const establishment = await readEstablishment(establishmentId);
        let establishmentInfo = {};

        if (establishment?.fotoThumb) {
          const thumbnailUrl = await downloadImage('estabelecimento', establishment.fotoThumb);

          establishmentInfo = {
            ...establishment,
            fotoThumb: thumbnailUrl,
          };
        }

        setLoading(false);
        // OBS: QUANDO CRIAR A INTERFACE DE ADM DE LINKS, DESCOMENTE A LINHA A BAIXO.
        // setBackgroundImage(links[0]);

        if (establishment === null) {
          setMessage('Estabelecimento não encontrado.😢');
          return;
        }

        if (!establishment?.useFullLinks) {
          setMessage('Estabelecimento ainda não possui link. 😢');
        }

        setEstablishmentData(establishmentInfo);
      } catch {
        setLoading(false);
        setIsErrorApi(true);
      }
    };

    getEstablishmentInfo();
  }, []);

  /*   function setBackgroundImage(img) {
    setIsbackgroundImg(true);
    const element = document.getElementById("content-estab-links");
    element.style.backgroundImage = `url(${img})`;
  } */

  if (loading) {
    return <LoadingInfoEstablishment />;
  } else if (!loading && isErrorApi) {
    return <ErrorMessageEstablishmentLinks />;
  }

  return (
    <div
      id="content-estab-links"
      className="container-estab-links background-img-esta-link paper-container"
    >
      <header className="header-estab-links">
        <div className="header-content-estab-links">
          <span className="name-estab-links">{establishmentData?.nome}</span>
        </div>
        <img
          className="img-estab-links"
          onLoad={() => setLoadingThumbnail(true)}
          src={loadingThumbnail ? establishmentData?.fotoThumb : placeholderThumbnail}
          alt="Foto do estabelecimento"
        />
      </header>

      {message === '' ? (
        <main className="container-fluid-estab-links">
          {/* LINKS */}
          <div className="button-link-estab-links">
            {establishmentData &&
              establishmentData.useFullLinks.map((item, index) => (
                <a key={index} href={item.link} rel="noreferrer" target="_blank">
                  {item.title}
                </a>
              ))}
          </div>

          <div className="warpper-content-media-estab-links">
            <div
              /* className="content-media-estab-links" */ style={{
                background: `${!isBackgroundImg ? 'var(--primary-color-links)' : ''}`,
              }}
            >
              {/* REDE SOCIAIS */}
              {establishmentData?.redesSociais && (
                <SocialMedia redesSociais={establishmentData.redesSociais} />
              )}
              {/* CONTATOS */}
              {establishmentData?.contatoComercial && (
                <>
                  {establishmentData?.redesSociais && establishmentData?.contatoComercial && (
                    <hr className="line-estab-links" />
                  )}
                  <BusinessContact contatoComercial={establishmentData.contatoComercial} />
                </>
              )}
            </div>
          </div>
        </main>
      ) : (
        <h1 className="message-estab-links">{message}</h1>
      )}

      <footer
        className="footer-estab-links"
        style={{
          background: `${!isBackgroundImg ? 'var(--primary-color-links)' : ''}`,
        }}
      >
        <div className="warpper-footer-estb-links">
          <span>{t('general.download_triibo_app')}</span>
          <div className="wrapper-estab-links">
            <a href="https://triibo.page.link/TWAF" target="new">
              <img src={IOS_img} alt="IOS loja" />
            </a>
            <a href="https://triibo.page.link/1c84" target="new">
              <img src={androidImg} alt="Android loja" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default EstablishmentLinks;
