export default function reducer(state = {
  cellPhone: null,
  userInfo: null

}, action) {

  switch (action.type) {

    case 'CODE_INPUT_PENDING':
      {
        return {
          ...state,
          cellPhone: null,
          userInfo: null
        };
      }

    case 'CODE_INPUT_FULFILLED':
      { 
        return {
          ...state,
          cellPhone: action.payload.cellPhone,
          userInfo: action.payload.userInfo
        };
      }

    case 'CODE_INPUT_REJECTED':
      {
        return {
          ...state,
          cellPhone: null,
          userInfo: null
        };
      }

    default:
      return state;
  }
}